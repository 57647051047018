<template>
  <div class="sds">
    <vue-headful title="Safety Data Sheets | Goodyear Chemical" description="Search our safety data sheet database for more information at www.goodyearchemical.com."/>
     <section class="main-section">
      <b-container fluid>
        <b-row>
          <b-col class="text-center"  sm="12">
            <h1>Safety Data Sheets (SDS) Search</h1>
            <br/><p class="red"><b>We are currently working on our files.</b> </p>
            <p>If you need a safety data sheet in the meantime, please
              <a href="mailto:mbeke_perez@goodyear.com?subject=Need%20Goodyear%20SDS%20Sheets&cc=david_seifert@goodyear.com"> email us</a>.
              </p>
            <!--<h2>Safe, responsible chemical use </h2>
            <p>If you need additional Safety Data Sheet language versions, please
              <a href="/contact-us"> contact us > </a>
            </p>
            <div class="filter-results">
              <input type="text" v-model="search" placeholder="Search by product name" />
            </div>-->
          </b-col>
        </b-row>
      </b-container>
      <!-- <b-container>
        <b-row>
          <b-col sm="12" md="4" v-for="sds in filteredCustomers" v-bind:key="sds.ProductName">
            <a v-bind:href="`${sds.DocumentList[0].ELink}`" class="card-link" target="_blank">
              <b-card>
                <b-card-text>
                    <p class="prod-title">{{sds.ProductName}}</p>
                    <p>{{sds.ProductGroup}}</p>
                </b-card-text>
              </b-card>
            </a>
          </b-col>
        </b-row>
      </b-container> -->
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'Sds',
  data () {
    return {
      safetyDataSheets: '',
      search: ''
    }
  },
 /*mounted () {
    this.getSdsList()
  },*/
  methods: {
    getSdsList () {
      axios.post('/api/getSafetySheets')
        .then((response) => {
          console.log(response.data)

          if (response.data.code === 0) {
            console.log('success! ' + response.data.sds)
            this.safetyDataSheets = response.data.sds.CatalogDetailsList
            console.log('this.safetyDataSheets now: ' + this.safetyDataSheets)
          } else {
            console.log('error! ' + response.data)
          }
        })
        .catch((error) => {
          console.log('caught an error: ' + error)
        })
    },
    sortList () {
      const listSize = this.safetyDataSheets.length

      // eslint-disable-next-line
      if (listSize != 0) {
        for (var i = 0; i <= listSize - 1; i++) {
          this.safetyDataSheets = this.safetyDataSheets.filter(function (i) {
            return i.ProductGroup
          })
        }
      }
    }
  },
  computed: {
    orderedSds: function () {
      return _.orderBy(this.safetyDataSheets, 'ProductGroup')
    },
    filteredCustomers: function () {
      var self = this
      return this.orderedSds.filter(function (sds) { return sds.ProductName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sds {
  min-height: 500px;
  padding-top: 50px;
}
h1 {
  margin-bottom: 0;
  font-weight: 700;
}
h2 {
  font-weight: 700;;
  font-size: 1.70rem;
  color: #888;
}
.filter-results {
  margin-top: 20px;
  margin-bottom: 35px;
}
.filter-results input {
  height: 45px;
  font-size: 20px;
  padding: 6px 12px;
  display: block;
  margin: 0 auto;
  width: 690px;
}
.card {
  margin-bottom: 20px;
  -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  background-color: #c0d1ed;
  border-radius: 0px;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
a.card-link {
  color: #333;
}
a.card-link:hover, .card:hover {
  background-color: #85a6dc;
}
.card-body {
  background-image: url("../assets/images/icons/arrow-down.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 11%;
  padding: 10px;
}
.prod-title {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: rgb(0,40,120);
}

@media (max-width: 768px) {
  .filter-results input {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .card {
    min-height: 135px;
  }
  .card-body {
    background-size: 15%;
  }
}
</style>
