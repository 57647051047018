<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: { Header, Footer }
}
</script>

<style>
body #app {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  color: #333;
}
</style>
