<template>
  <div class="home">
    <vue-headful title="Goodyear Chemical" description="Goodyear Chemical makes quality rubber and certain chemicals that go into some consumer and industrial products used around the world."/>
    <div class="page-background"></div>
    <section class="main-section">
      <b-container fluid>
        <b-row>
          <b-col>
            <div class="masthead">
              <div class="masthead-icon">
                <div class="icon-container">
                    <img class="masthead-img" src="../assets/images/goodyear-masthead.png" alt="Goodyear Logo"/>
                </div>
              </div>
              <div class="text-content">
                <h1>Chemical</h1>
                <h2>Advantaged products and a superior service experience </h2>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="prod-section">
      <b-container fluid>
        <b-row>
          <b-col>
            <h2>Our Quality Products</h2>
            <p>New products and technical discoveries are part of the everyday routine here at Goodyear Chemical, and we’re working continuously to improve, enhance and innovate.</p>
            <router-link class="btn" to="/products">Find A Product</router-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div class="separator"></div>

    <section class="promo-section">
      <b-container>
        <b-row>
          <b-col sm="12" md="6">
            <div class="home-promo">
              <h2>About Us</h2>
              <p>At Goodyear Chemical, we’re focused on the quality of rubber products. Understanding and meeting customer needs is our mission, and that includes supplying the global marketplace with the highest quality products. </p>
              <router-link class="btn" to="/our-company">Get to know us</router-link>
            </div>
          </b-col>

          <b-col sm="12" md="6">
            <div class="home-promo">
              <h2>Connecting with Us</h2>
              <p>Working closely with our customers is the nature of our business, and we’re committed to doing it well. That means being there for you, whenever and wherever you need us, to answer questions and solve challenges. </p>
              <router-link class="btn" to="/contact-us">Reach Out To Us</router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding: 24px;
}
.page-background {
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  z-index: -1;
  background-image: url('../assets/images/background/beaumont_2-md.jpg');
}
.main-section {
  min-height: 400px;
}
.separator {
  background-color: #0b286f;
  min-height: 35px;
}
.btn {
  min-width: 100%;
}
.prod-section {
  min-height: 280px;
  text-align: center;
  color: #fff;
  background: -webkit-gradient(linear,left top,right bottom,from(#163c9b),to(#3272c7));
  background: linear-gradient(to bottom right,#163c9b,#3272c7);
}
.prod-section h2 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 36px;
  padding: 15px 0px;
}
.prod-section .btn {
  margin-top: 25px;
}
.promo-section {
  background-color: rgba(204,204,204, .96);
  background-image: url('../assets/images/diagonal.png');
  background-repeat: repeat;
  background-color: #999;
}
.promo-section .flex {
  padding: 15px;
}
.home-promo {
  background-color: #fff;
  text-align: left;
  padding: 15px 15px 5px 15px;
  min-height: 275px;
}
.home-promo h2 {
  font-family: "Barlow", sans-serif;
  font-size: 28px;
  margin-bottom: 15px;
  padding-top: 5px;
  font-weight: 700;
}
.home-promo .btn {
  float: right;
  min-width: 150px;
}

@media (max-width: 445px) {
  .page-background {
    background-image: url('../assets/images/background/beaumont_2-xs.jpg');
  }
}
@media (min-width: 445px) {
  .btn {
    min-width: 250px;
  }
}
@media (min-width:446px) and (max-width: 768px) {
  .page-background {
    background-image: url('../assets/images/background/beaumont_2-sm.jpg');
  }
}
@media (max-width: 767px) {
  .home-promo {
    margin-bottom: 25px
  }
}
@media (min-width: 768px) {
  .promo-section .container {
    padding: 50px 24px;
  }
}
@media (max-width: 960px) {
  .home {
    z-index: -1;
    position: relative;
  }
}
@media (min-width: 1038px) {
  .home-promo .btn {
    margin-top: 30px;
  }
}
@media (min-width: 1200px)  {
  .home-promo {
    min-height: 250px;
  }
}
@media (min-width:1281px) and (max-width: 1600px) {
  .page-background {
    background-image: url('../assets/images/background/beaumont_2-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px) {
  .page-background {
    background-image: url('../assets/images/background/beaumont_2-xl.jpg');
  }
}

</style>
