<template>
  <div class="company">
    <vue-headful title="Our Company | Goodyear Chemical" description="Goodyear Chemical facilities and plants supply quality rubber and chemicals used in some consumer and industrial products. Learn more."/>
    <section class="main-section">
      <b-container fluid>
        <div class="section-background"></div>
        <b-row>
          <b-col class="text-center"  sm="12">
            <h1>Goodyear Chemical</h1>
            <h2>Working closely with the customers is the nature of our business  </h2>
            <a class="btn" href="/contact-us">Contact Us</a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="plant-section">
      <b-container>
        <b-row>
          <b-col sm="12">
            <div role="tablist">

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-1 variant="info">Akron Research & Development Facility - Ohio</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="company-accordion" role="tabpanel">
                  <b-card-body>
                    <img src="/images/company/plant-archwood.jpg" alt="R&D Facility"/>
                    <b-card-text>Goodyear Chemical’s Research & Development Facility is located in Akron, Ohio. The Facility is focused on providing world-class research and development along with technical assistance across our product portfolio. R&D capabilities range from small scale synthesis labs to pilot facilities for product and process development. We have a diverse technical skill set, which includes: organic chemists, polymer chemists, physicists, material scientists, engineers and technicians. These staff members support all Goodyear Chemical R&D activities.</b-card-text>
                    <b-card-text>We have multiple small-scale operations within our R&D facility. These operations support the development of new products, provide samples for application testing, perform customer evaluations, and generate process data for plant scale up.</b-card-text>
                    <b-card-text>Our R&D Facility has extensive analytical capabilities. This includes a full suite of techniques to measure polymer microstructure and macrostructure, as well as techniques for small molecule analysis. The Facility also has testing capabilities for basic high solids latex properties such as tension, viscosity, pH, and coagulum. </b-card-text>
                    <b-card-text>The R&D Facility also has infrastructure to support the small scale mixing of rubber compounds, many of which can be found in end products that are manufactured using Goodyear Chemical polymers. Compounded materials routinely undergo chemical and physical testing. In the event that more specialized or sensitive testing is required, other Goodyear resources can be leveraged.</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-2 variant="info">Bayport Plant - Texas</b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="company-accordion" role="tabpanel">
                  <b-card-body>
                    <img src="/images/company/plant-bayport.jpg" alt="Bayport Plant"/>
                    <b-card-text>The Goodyear Bayport Chemical Plant is located on 55 acres between Pasadena and LaPorte, Texas in the Bayport Industrial District. The Plant was built in 1971 and leased to Goodyear in 1973. Goodyear purchased the Plant in 1978. The Plant primarily makes Hydroquinone, the main feedstock for our Niagara Falls Chemical Plant. The plant is highly automated and has both continuous and batch operations. </b-card-text>
                    <b-card-text><strong>Products Made at Bayport Plant</strong><br/>
                      <ul>
                        <li>HYDROQUINONE</li>
                        <li>META and PARA DIPB</li>
                        <li>ACETONE</li>
                        <li>ANTIOXIDANTS</li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-3 variant="info">Beaumont Plant - Texas</b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="company-accordion" role="tabpanel">
                  <b-card-body>
                    <img src="/images/company/plant-beaumont.jpg" alt="Beaumont Plant"/>
                    <b-card-text>The Goodyear Beaumont Chemical Plant was built on a Texas rice field in 1961. The Plant has become one of the world’s largest manufacturers of synthetic rubber products. Products manufactured at the Beaumont Plant are exported throughout the world.</b-card-text>
                    <b-card-text>A diverse facility, the Plant has four separate businesses: <br/>
                      <ol>
                        <li>The Plant’s largest business is the BUDENE® units, which produces synthetic rubber- primarily for tires; </li>
                        <li>The Isoprene and Natsyn® facilities produce synthetic rubber for a wide range of applications; </li>
                        <li>The Beaumont Plant makes synthetic rubber for tires and golf balls. </li>
                      </ol>
                    </b-card-text>
                    <b-card-text>The Beaumont Plant's mission is to create a culture of personal satisfaction, safety, and environmental protection, as well as meet our customers’ needs, and create financial success for Goodyear and its associates. </b-card-text>
                    <b-card-text>Interesting fact: Goodyear Beaumont generates its own electrical power and exports excess megawatts to the local grid.</b-card-text>
                    <b-card-text><strong>Products Made at Beaumont Plant</strong><br/>
                      <ul>
                        <li>BUDENE® - Butadiene Rubber </li>
                        <li>SLF® - Solution Styrene Butadiene Rubber </li>
                        <li>NATSYN® - Isoprenic Rubber </li>
                        <li>WINGFLEX™ - DCPD (Dicyclopentadiene) </li>
                        <li>ISOPRENE </li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-4 variant="info">Houston Plant - Texas</b-button>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="company-accordion" role="tabpanel">
                  <b-card-body>
                    <img src="/images/company/plant-houston.jpg" alt="Houston Plant"/>
                    <b-card-text>The Goodyear Houston Chemical Plant sits on 130 acres close to the Houston Ship Channel and just east of downtown Houston. The Plant is one of the original sixteen synthetic rubber plants formerly owned by the US government that were built to produce GR-S “government rubber-styrene” in support of the World War II war effort. Although initially owned by the US government, Goodyear purchased the Plant in 1955 and has operated the Plant since its start-up in 1943. The Plant manufactures Emulsion Styrene-Butadiene Rubber (ESBR). </b-card-text>
                    <b-card-text><strong>Products Made at Houston Plant</strong><br/>
                      <ul>
                        <li>PLIOFLEX®</li>
                        <li>HIGH SOLIDS LATEX</li>
                        <li>PLIOGUM®</li>
                      </ul>
                      </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-5 variant="info">Niagara Falls Plant - New York</b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="company-accordion" role="tabpanel">
                  <b-card-body>
                    <img src="/images/company/plant-niagara.jpg" alt="Niagara Falls Plant"/>
                    <b-card-text>The Goodyear Niagara Falls, New York Chemical Plant was built in 1946. Initially built for PVC manufacturing, the Plant also began production of Rubber Antioxidants in 1957. PVC production was discontinued in 1996, however, the Plant continues to manufacture antioxidants.</b-card-text>
                    <b-card-text>Antioxidant products manufactured at the Plant are shipped globally. The Plant is located on 28 acres near the historic tourist destination of Niagara Falls. Plant occupies 210,000 square feet of building space and contains three reactor systems. The Plant has two finishing lines, which are a pastillator and a flaker.</b-card-text>
                    <b-card-text><strong>Products Made at Niagara Falls Plant</strong><br/>
                      <ul>
                        <li>POLYSTAY® 100 (NAILAX) </li>
                        <li>POLYSTAY® 100AZ </li>
                        <li>POLYSTAY® 200 </li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Company'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.company {
  background-color: #ccc;
  background-image: url('../../assets/images/diagonal-lines-two.png');
  background-repeat: repeat;
}
.main-section .section-background {
  background-position: 100% 30%;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 310px;
  z-index: 0;
  position: absolute;
  width: 100%;
  background-image: url('../../assets/images/background/archwood_research-md.jpg');
  margin-top: 108px;
}
.main-section .container-fluid {
  padding: 0px;
}
.main-section h1 {
  margin-top: 55px;
  text-transform: uppercase;
  color: #274ba7;
  padding: 7px;
  border: 7px solid #fd0;
  display: inline-block;
  background-color: rgba(255,255,255,0.8);
  font-weight: 700;
}
.main-section h2 {
  color: #fff;
  margin-top:10px;
  margin-bottom:15px;
  text-transform: uppercase;
  font-weight: 700;
}
.plant-section .container {
  margin-top: 92px;
}
.plant-section .btn {
  background-color: #1e468d;
  background-image: none;
  color: #fff;
  font-size: 28px;
  text-align: left;
}
.plant-section .btn:hover {
  color: #fff;
  text-decoration: underline;
}
.plant-section .btn-info:hover {
  background-color: #325ecf;
  border-color: #1e468d;
}
.plant-section .btn-info:not(:disabled):not(.disabled):active, .plant-section .btn-info:not(:disabled):not(.disabled).active, .plant-section .show > .btn-info.dropdown-toggle {
  background-color: #1e468d;
  border-color: #1e468d;
}
.card-body img {
  float: right;
  padding-left: 5px;
  padding-bottom: 10px;
}

@media (max-width: 445px) {
  .main-section .section-background  {
    background-image: url('../../assets/images/background/archwood_research-xs.jpg');
  }
}
@media (min-width:446px) and (max-width: 768px) {
  .main-section .section-background  {
    background-image: url('../../assets/images/background/archwood_research-sm.jpg');
  }
}
@media (max-width: 768px) {
  .main-section .section-background {
    height: 350px;
  }
  .card-body {
    text-align: center;
  }
  .card-body img {
    max-width: 55%;
  }
}
@media (min-width: 768px) {
  .main-section h1 {
    font-size: 3.75rem;
  }
}
@media (min-width:1281px) and (max-width: 1600px) {
  .main-section .section-background  {
    background-image: url('../../assets/images/background/archwood_research-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px) {
  .main-section .section-background  {
    background-image: url('../../assets/images/background/archwood_research-xl.jpg');
  }
}
</style>
