import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import Certifications from '@/components/Certs'
import Company from '@/components/Company/Company'
import Contact from '@/components/Contact'
import Products from '@/components/Products/Products-landing'
import ProductDetails from '@/components/Products/Details'
import SampleRequest from '@/components/Sample-request'
import Sds from '@/components/Sds'
import Tech from '@/components/Technology'
import error404 from '@/components/404'
import Wingflex from '@/components/Products/Wingflex'

import '@/assets/styles/style.css'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/certifications',
      name: 'Certifications',
      component: Certifications
    },
    {
      path: '/contact-us',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/our-company',
      name: 'Company',
      component: Company
    },
    {
      path: '/products',
      name: 'Products',
      component: Products
    },
    {
      path: '/products/antioxidants',
      name: 'Antioxidants',
      component: ProductDetails
    },
    {
      path: '/products/dicyclopentadienes',
      name: 'dicyclopentadienes',
      component: ProductDetails
    },
    {
      path: '/products/dicyclopentadienes/wingflex',
      name: 'wingflex',
      component: Wingflex
    },
    {
      path: '/products/emulsion-styrene-butadiene-rubber',
      name: 'emulsion-styrene-butadiene-rubber',
      component: ProductDetails
    },
    {
      path: '/products/food-grade',
      name: 'food-grade',
      component: ProductDetails
    },
    {
      path: '/products/high-solids-latex',
      name: 'high-solids-latex',
      component: ProductDetails
    },
    {
      path: '/products/isoprenic-rubber',
      name: 'isoprenic-rubber',
      component: ProductDetails
    },
    {
      path: '/products/polybutadiene-rubber',
      name: 'polybutadiene-rubber',
      component: ProductDetails
    },
    {
      path: '/products/solution-styrene-butadiene-rubber',
      name: 'solution-styrene-butadiene-rubber',
      component: ProductDetails
    },
    {
      path: '/products/specialty-chemicals',
      name: 'specialty-chemicals',
      component: ProductDetails
    },
    {
      path: '/request-sample',
      name: 'Sample-request',
      component: SampleRequest
    },
    {
      path: '/safety-data-sheets',
      name: 'SDS',
      component: Sds
    },
    {
      path: '/technology',
      name: 'Technology',
      component: Tech
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    }
  ]
})
