<template>
  <div class="certifications">
    <vue-headful title="Certifications | Goodyear Chemical" description="Our safety certifications are major achievements that we take great pride in. They reflect the hard work and unwavering commitments of our associates. Learn more."/>
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Our Certifications</h1>
            <p>At Goodyear, we strive to contribute to sustainable economic development and environmental protection while seeking to improve the quality of life of our associates, families, communities, and society in general. For more information, read our <a href="https://corporate.goodyear.com/en-US/about/mission/environmental-health-safety-policy.html" target="_blank">Environmental, Health & Safety (EHS) Policy ></a></p>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="banners">
        <b-row>
          <b-col>
              <img src="/images/certs/vpp-banner.png" alt="VPP Banner"/>
          </b-col>
          <b-col>
              <img src="/images/certs/iso-9001-2015.png" alt="ISO 9001"/>
          </b-col>
          <b-col>
              <img src="/images/certs/iso-14001.png" alt="Iso 14001"/>
          </b-col>
           <b-col>
              <img src="/images/certs/kosher1.jpg" alt="Kosher"/>
          </b-col>
           <b-col>
              <img src="/images/certs/halal1.png" alt="Halal"/>
          </b-col>
        </b-row>
      </b-container>
      <div class="divider">
        Certifications by Facility
      </div>
      <b-container>
        <b-row>
          <b-col sm="12" md="3">
            <h2>Bayport, TX</h2>
            <ul>
                <li>OSHA Voluntary Protection Program </li>
                <li><a href="/docs/certs/gy-bayport-iso-9001.pdf" target="_blank">ISO-9001</a></li>
                <li><a href="/docs/certs/gy-bayport-iso-14001.pdf" target="_blank">ISO-14001</a></li>
            </ul>
          </b-col>
          <b-col sm="12" md="3">
            <h2>Beaumont, TX</h2>
            <ul>
                <li>OSHA Voluntary Protection Program </li>
                <li><a href="/docs/certs/gy-beaumont-iso-9001.pdf" target="_blank">ISO-9001</a></li>
                <li><a href="/docs/certs/gy-beaumont-iso-14001.pdf" target="_blank">ISO-14001</a></li>
            </ul>
          </b-col>
          <b-col sm="12" md="3">
            <h2>Houston, TX</h2>
            <ul>
                <li>OSHA Voluntary Protection Program </li>
                <li><a href="/docs/certs/gy-houston-iso-9001.pdf" target="_blank">ISO-9001</a></li>
                <li><a href="/docs/certs/gy-houston-iso-14001.pdf" target="_blank">ISO-14001</a></li>
                <li><a href="/docs/certs/gy-houston-kosher-certificate.pdf" target="_blank">Kosher Certification</a></li>
                <li><a href="/docs/certs/gy-houston-halal-certificate.pdf" target="_blank">Halal Certification</a></li>
                <li><a href="/docs/certs/gy-houston-sqf-certificate.pdf" target="_blank">SQF Certification</a></li>
            </ul>
          </b-col>
          <b-col sm="12" md="3">
            <h2>Niagara Falls, NY</h2>
            <ul>
                <li>OSHA Voluntary Protection Program </li>
                <li><a href="/docs/certs/gy-niagara-falls-iso-9001.pdf" target="_blank">ISO-9001</a></li>
                <li><a href="/docs/certs/gy-niagara-falls-iso-14001.pdf" target="_blank">ISO-14001</a></li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'Certifications'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.certifications {
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
}
h1 {
  font-weight: 700;
  font-size: 2.5rem;
}
h2 {
  font-weight: 700;
  font-size: 1.35rem;
}
.banners {
  margin-top: 20px;
  margin-bottom: 20px;
}
.banners .col {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.banners img {
  height: 100px;
}
.divider {
  min-height: 50px;
  background-color: #ccc;
  text-align: center;
  padding: 7px 0px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  color: #274ba7;
  margin-top: 25px;
  margin-bottom: 18px;
  border-bottom: 2px solid #ffdd00;
  border-top: 2px solid #ffdd00;
}

@media (min-width:768px) and (max-width:992px) {
  .sm-box {
    min-height: 195px;
  }
}
@media (min-width:993px) and (max-width:1199px) {
  .sm-box {
    min-height: 155px;
  }
}
@media (min-width:1200px) {
  .sm-box {
    min-height: 125px;
  }
}
</style>
