<template>
  <div class="prod-details">
    <vue-headful :title="products[productCounter].prod_name + ' Product Details | Goodyear Chemical'" :description="products[productCounter].seo_desc"/>
    <section class="main-section">
      <b-container>
        <div class="detail-info">
          <img :src="'/images/products/' + products[productCounter].img_url" :alt="products[productCounter].prod_name + ' Product Image'" />
          <h1>{{products[productCounter].prod_name}}</h1>
          <h2>{{products[productCounter].prod_desc}}</h2>
          <router-link class="btn" to="/request-sample">Request Sample</router-link>
        </div>
        <b-row class="learn-more" align-v="center" align-h="center" v-show="isDCPD()">
          <b-col class="text-center align-self-center" sm="12" md="2" lg="2">
            <b-iconstack class="icon">
              <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
              <b-icon stacked icon="search" scale="0.6"></b-icon>
            </b-iconstack>
          </b-col>
          <b-col class="align-self-left mt-3 mb-3" sm="12" md="7" lg="7">
            <b>Discover Goodyear's premium range of Dicyclopentadiene (DCPD) solutions under the Wingflex® brand</b>
          </b-col>
          <b-col class="text-center align-self-center mt-3 mb-3" sm="12" md="3" lg="3">
            <router-link class="learn-more-btn" to="/products/dicyclopentadienes/wingflex">
              Learn More&#160;<b-icon icon="arrow-right"></b-icon>
            </router-link>
          </b-col>
        </b-row>
        
      </b-container>
    </section>
    <section class="details-section">
      <b-container>
        <b-row>
          <b-col sm="12">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-1 variant="info">Product Information</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="details-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="gy-table-row row" v-for="spec in products[productCounter].specs" v-bind:key="spec[0][1]">

                      <div class="specs-table col-sm-12 col-md-6">
                        <span class="specs-title">Technical Name</span>
                        <span class="specs-description first">{{spec[0][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6">
                        <span class="specs-title">Safety Data Sheet</span>
                        <span class="specs-description"><router-link to="/safety-data-sheets">Find SDS ></router-link></span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6">
                        <span class="specs-title">Technical Data Sheet</span>
                        <span class="specs-description">
                          <a v-if="spec[2][1]" v-bind:href="`/docs/tds/${spec[2][1]}`" target="_blank">Open TDS ></a>
                          <span v-if="!spec[2][1]">N/A</span>
                          </span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6">
                        <span class="specs-title">{{spec[3][0]}}</span>
                        <span class="specs-description">{{spec[3][1]}} </span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6">
                        <span class="specs-title">{{spec[4][0]}}</span>
                        <span class="specs-description">{{spec[4][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[5]">
                        <span class="specs-title">{{spec[5][0]}}</span>
                        <span class="specs-description">{{spec[5][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[6]">
                        <span class="specs-title">{{spec[6][0]}}</span>
                        <span class="specs-description">{{spec[6][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[7]">
                        <span class="specs-title">{{spec[7][0]}}</span>
                        <span class="specs-description">{{spec[7][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[8]">
                        <span class="specs-title">{{spec[8][0]}}</span>
                        <span class="specs-description">{{spec[8][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[9]">
                        <span class="specs-title"> {{spec[9][0]}}</span>
                        <span class="specs-description">{{spec[9][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[10]">
                        <span class="specs-title"> {{spec[10][0]}}</span>
                        <span class="specs-description">{{spec[10][1]}}</span>
                      </div>
                      <div class="specs-table col-sm-12 col-md-6" v-if="spec[11]">
                        <span class="specs-title"> {{spec[11][0]}}</span>
                        <span class="specs-description">{{spec[11][1]}}</span>
                      </div>
                    </div>
                    <div v-show="isIR()">* Mooney ML1+4 @ 100°C <br> ** for more information on the test method inquire with Goodyear Chemical</div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section v-show="isIR()" class="details-section">
      <b-container>
        <b-row>
          <b-col sm="12">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle.accordion-2 variant="info">Additional Information</b-button>
                </b-card-header>
                <b-collapse id="accordion-2" visible accordion="details-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="gy-table-row row">
                      <div class="specs-table col-sm-12 col-md-12">
                        <h3><b-icon icon="box-seam"></b-icon> Packaging Options</h3>
                        <p><b>Returnable Container</b></p>
                        <ul>
                          <li><b>Durability:</b> Our metal boxes are sturdy and designed for repeated use.</li>
                          <li><b>Capacity:</b> Each box comfortably holds 36 film wrapped bales, with each bale weighing approximately 75 lbs.</li>
                          <li><b>Stackable:</b> These boxes can be stacked up to four high, optimizing space during storage.</li>
                          <li><b>Weight:</b> The gross weight of each box is approximately 2958 lbs.</li>
                        </ul>
                        <p><b>Cardboard Boxes on Wooden Pallets</b></p>
                        <ul>
                          <li><b>Capacity:</b> Each cardboard box contains 27 film wrapped bales, with each bale weighing approximately 75 lbs.</li>
                          <li><b>Dimensions:</b> The dimensions of each box on a pallet are approximately W50” x D32” x H55”.</li>
                          <li><b>Stackable:</b> these boxes can be stacked two high.</li>
                        </ul>
                      </div>
                      <div class="specs-table col-sm-12 col-md-12">
                        <h3><b-icon icon="thermometer"></b-icon> Storage and Handling</h3>
                        <p>NATSYN®, renowned for its quality and durability, requires appropriate storage to maintain its specified properties. To ensure that you get the best from our product, we recommend the following storage guidelines:</p>
                        <ul>
                          <li><b>Ideal Storage Conditions:</b> Keep NATSYN® in its original packaging to preserve its integrity. Store in a dry environment, safeguarding it from potential damage caused by moisture.</li>
                          <li><b>Avoid Heat and Ultraviolet Light:</b> Exposure to heat and ultraviolet light can adversely affect the quality of NATSYN® polyisoprene. Choose a storage location that is cool and protected from direct sunlight and other sources of UV light.</li>
                          <li><b>Retesting for Continued Excellence:</b> To guarantee the consistent performance of NATSYN®, we advise retesting the product at least every 18 months from the date of manufacture. This periodic testing is crucial in ensuring the material continues to meet your expectations and our standards.</li>
                          <li><b>Recommended Test:</b> The Mooney test is suggested to assess the viscosity of NATSYN®. This test provides valuable insights into the quality and processing characteristics of the rubber, ensuring its suitability for your specific applications.</li>
                        </ul>
                      </div>
                      <div class="specs-table col-sm-12 col-md-12">
                        <h3>
                          <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_6_3739)">
                            <path d="M11.7402 20.47C15.6902 20.47 19.2802 17.77 20.2402 13.76C20.7802 11.49 20.4002 9.15002 19.1802 7.16002C17.9602 5.17002 16.0302 3.78002 13.7602 3.24002C9.0702 2.12002 4.3502 5.03002 3.2402 9.71002C2.7002 11.98 3.0702 14.32 4.3002 16.31C5.5302 18.3 7.4502 19.69 9.7202 20.23C10.3902 20.4 11.0702 20.47 11.7402 20.47ZM11.7402 5.00002C12.2602 5.00002 12.7802 5.06002 13.3002 5.19002C15.0502 5.60002 16.5402 6.68002 17.4802 8.21002C18.4202 9.74002 18.7102 11.55 18.2902 13.3C17.4302 16.91 13.7902 19.15 10.1802 18.29C8.4302 17.87 6.9502 16.8 6.0002 15.27C5.0502 13.74 4.7702 11.93 5.1802 10.18C5.9202 7.09002 8.6902 5.00002 11.7402 5.00002Z" fill="#222222"/>
                            <path d="M14.7498 16.63C13.8298 17.19 12.7998 17.49 11.7498 17.49C11.2998 17.49 10.8498 17.44 10.4098 17.33C8.91975 16.97 7.64975 16.06 6.84975 14.75C6.04975 13.44 5.79975 11.9 6.14975 10.41C6.88975 7.33003 9.98975 5.41003 13.0698 6.15003C14.5698 6.50003 15.8298 7.42003 16.6398 8.73003C17.4398 10.04 17.6898 11.58 17.3398 13.07C16.9798 14.56 16.0598 15.83 14.7498 16.63ZM14.8598 13.8C15.0998 13.44 15.2898 13.05 15.3898 12.61C15.4998 12.13 15.5198 11.65 15.4398 11.17L13.8598 11.72C13.5098 11.84 13.3998 12.29 13.6598 12.56L14.8598 13.8ZM14.0598 14.66L12.7498 13.53C12.4698 13.28 12.0198 13.42 11.9198 13.78L11.4398 15.46C12.3998 15.54 13.3298 15.24 14.0598 14.66ZM10.2998 15.18L10.6298 13.47C10.6998 13.1 10.3598 12.78 9.99975 12.88L8.34975 13.29C8.72975 14.13 9.40975 14.81 10.2998 15.18ZM8.67975 9.59003C8.40975 9.97003 8.20975 10.4 8.08975 10.87C7.98975 11.3 7.97975 11.73 8.02975 12.15L9.60975 11.6C9.95975 11.48 10.0698 11.03 9.80975 10.76L8.67975 9.59003ZM11.7398 7.99003C10.9198 7.99003 10.1498 8.26003 9.50975 8.74003L10.7298 9.79003C11.0098 10.03 11.4598 9.90003 11.5598 9.54003L11.9998 8.02003C11.9098 8.01003 11.8298 7.99003 11.7398 7.99003ZM13.1598 8.28003L12.8598 9.85003C12.7898 10.22 13.1298 10.54 13.4898 10.44L15.0698 10.05C15.0198 9.96003 14.9898 9.86003 14.9298 9.77003C14.5098 9.09003 13.8898 8.58003 13.1598 8.28003Z" fill="#222222"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_6_3739">
                            <rect width="17.48" height="17.47" fill="white" transform="matrix(-1 0 0 1 20.48 3)"/>
                            </clipPath>
                            </defs>
                          </svg>
                          Applications
                        </h3>
                        <p>Our products are engineered to excel in a variety of applications, demonstrating exceptional flexibility, durability, and performance in:</p>
                          <ul>
                            <li><b>Medical Products:</b> Gloves, catheters requiring flexibility and fit.</li>
                            <li><b>Industrial Use:</b> Seals, gaskets</li>
                            <li><b>Adhesive Manufacturing:</b> High-quality adhesives for various applications.</li>
                            <li><b>Sporting Goods:</b> Durable elastomeric products, including sporting equipment.</li>
                            <li><b>Automotive Industry:</b> Reliable automotive and conveyor belts, engine mounts.</li>
                            <li><b>Consumer Goods:</b> Footwear, baby care products, condoms offering comfort and safety.</li>
                            <li><b>Tire Industry:</b> Tire compounds that provide strength and endurance.</li>
                          </ul>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Details',
  methods: {
    checkCurrUrl: function () {
      // get product from current url
      const productUrl = window.location.pathname.substr(10)

      let productCounter
      switch (productUrl) {
        case 'antioxidants':
          productCounter = 0
          break
        case 'emulsion-styrene-butadiene-rubber':
          productCounter = 1
          break
        case 'food-grade':
          productCounter = 2
          break
        case 'high-solids-latex':
          productCounter = 3
          break
        case 'isoprenic-rubber':
          productCounter = 4
          break
        case 'polybutadiene-rubber':
          productCounter = 5
          break
        case 'solution-styrene-butadiene-rubber':
          productCounter = 6
          break
        case 'specialty-chemicals':
          productCounter = 7
          break
        case 'dicyclopentadienes':
          productCounter = 8
          break
      }
      return productCounter
    },
    isDCPD: function() {
      let isDCPD = false;
      const productUrl = window.location.pathname.substr(10);

      isDCPD = productUrl == 'dicyclopentadienes'?true:false;
      return isDCPD;
    },
    isIR: function() {
      let isIR = false;
      const productUrl = window.location.pathname.substr(10);

      isIR = productUrl == 'isoprenic-rubber'?true:false;
      return isIR;
    }
  },
  data: function () {
    return {
      productCounter: this.checkCurrUrl(),
      products: [
        { id: 0,
          prod_name: 'Antioxidants',
          prod_desc: 'POLYSTAY®',
          img_url: 'antioxidants-used-in.jpg',
          seo_desc: 'Goodyear Chemicals POLYSTAY® family of products has been used as an antioxidant, antiozonant and anti-flex cracking agent in polymers, industrial products, tires, rubber processing and general rubber goods and as a stabilizer for staining ESBR.',
          specs: [
            /* 0 */ [ ['tech_name', 'POLYSTAY® 29'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'polystay_29.pdf'], ['Melting Point', 'NA'], ['Appearance', 'Clear Viscious Liquid'] ],
            /* 1 */ [ ['tech_name', 'POLYSTAY® 100'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'polystay_100.pdf'], ['Melting Point', '97'], ['Appearance', 'Blue-Brown Flake or Pastille'] ],
            /* 2 */ [ ['tech_name', 'POLYSTAY® 100AZ'], ['sds_link', '/safety-data-sheets'], ['tds_link', ''], ['Melting Point', '118'], ['Appearance', 'Blue-Brown Flake'] ],
            /* 4 */ [ ['tech_name', 'POLYSTAY® 200'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'polystay_200.pdf'], ['Melting Point', 'NA'], ['Appearance', 'Dark Colored Semi-Solid'] ]
          ]
        },
        { id: 1,
          prod_name: 'Emulsion Styrene Butadiene Rubber (ESBR)',
          prod_desc: 'PLIOFLEX®',
          img_url: 'esbr-used-in.jpg',
          seo_desc: 'Goodyear Chemical’s Plioflex elastomers have been used in tire compounds, automotive and conveyor belts, hoses, tubes, shoe products, sponge rubber and mechanical and sporting goods.',
          specs: [
            /* 0 */ [ ['tech_name', 'PLIOFLEX® 1502'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'plioflex_1502.pdf'], ['Mooney Viscosity', '44'], ['Bound Styrene, %', '23.5'], ['Oil Level, %', 'N/A'], ['Color', 'Light Tan'], ['Packaging', 'Returnable Container, Cardboard Box'] ],
            /* 2 */ [ ['tech_name', 'PLIOFLEX® 1723'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'plioflex_1723.pdf'], ['Mooney Viscosity', '49'], ['Bound Styrene, %', '23.5'], ['Oil Level, %', '27.3 (TDAE)'], ['Color', 'Dark Brown'], ['Packaging', 'Returnable Container, Cardboard Box'] ],
            /* 3 */ [ ['tech_name', 'PLIOFLEX® 1763'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'plioflex_1763.pdf'], ['Mooney Viscosity', '42'], ['Bound Styrene, %', '23.5'], ['Oil Level, %', '27.3 (Heavy Naphthenic)'], ['Color', ' Yellow to Amber'], ['Packaging', 'Returnable Container, Cardboard Box'] ],
            /* 4 */ [ ['tech_name', 'PLIOFLEX® 1769'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'plioflex_1769.pdf'], ['Mooney Viscosity', '46'], ['Bound Styrene, %', '40'], ['Oil Level, %', '27.3 (Heavy Naphthenic)'], ['Color', ' Yellow to Amber'], ['Packaging', 'Returnable Container, Cardboard Box'] ]
          ]
        },
        { id: 2,
          prod_name: 'Food Grade (ESBR)',
          prod_desc: 'PLIOGUM®',
          img_url: 'food-grade-used-in.jpg',
          seo_desc: 'Goodyear Chemical’s pliogum is a Kosher and Halal food grade polymer that has been used in food can sealant and bubble gum.',
          specs: [
            /* 0 */ [ ['tech_name', 'PLIOGUM® 1027'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'pliogum_1027.pdf'], ['Mooney Viscosity', '52'], ['Bound Styrene', '24'], ['BHT Analysis, %', '0.375'], ['Organic Acid, %', '5.4'], ['Packaging', 'Cardboard Box (HB3M)'] ],
            /* 1 */ [ ['tech_name', 'PLIOGUM® 1027 K 1'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'pliogum_1027K1.pdf'], ['Mooney Viscosity', '52'], ['Bound Styrene', '24'], ['BHT Analysis, %', '0.375'], ['Organic Acid, %', '5.4'], ['Packaging', 'J Box (ground crumb)'] ],
            /* 2 */ [ ['tech_name', 'PLIOGUM® 1028'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'pliogum_1028.pdf'], ['Mooney Viscosity', '58'], ['Bound Styrene', '47.5'], ['BHT Analysis, %', '0.375'], ['Organic Acid, %', '4.87'], ['Packaging', 'Cardboard Box (HB3M)'] ],
            /* 3 */ [ ['tech_name', 'PLIOGUM® 1028 K 1'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'pliogum_1028K1.pdf'], ['Mooney Viscosity', '58'], ['Bound Styrene', '47.5'], ['BHT Analysis, %', '0.375'], ['Organic Acid, %', '4.87'], ['Packaging', 'J Box (ground crumb)'] ],
            /* 4 */ [ ['tech_name', 'PLIOGUM® 1029 N'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'pliogum_1029N.pdf'], ['Mooney Viscosity', '58'], ['Bound Styrene', '47.5'], ['Mixed tocopherol analysis, %', '0.40'], ['Organic Acid, %', '4.87'], ['Packaging', 'Cardboard Box (HB3M)'] ]
          ]
        },
        { id: 3,
          prod_name: 'High Solids Latex (ESBR)',
          img_url: 'high-solids-latex-used-in.jpg',
          seo_desc: 'Goodyear Chemical’s latex product group is a family of high solids styrene/butadiene copolymer latices that has been used in flooring, foam, asphalt, sealants and roofing and mastic adhesives.',
          specs: [
            /* 0 */ [ ['tech_name', 'LPF 5356'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'lpf_5356.pdf'], ['Total Solids, %', '69.75'], ['Brookfield Viscosity, cps', '1400'], ['Bound Styrene', '23.75'], ['pH', '10.15'], ['Packaging', '275-gallon composite IBCs, flexitanks, tank trucks, and rail cars'] ],
            /* 1 */ [ ['tech_name', 'LPF 7814'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'lpf_7814.pdf'], ['Total Solids, %', '71'], ['Brookfield Viscosity, cps', '1600'], ['Bound Styrene', '23.75'], ['pH', '10.35'], ['Packaging', '275-gallon composite IBCs, flexitanks, tank trucks, and rail cars'] ],
            /* 2 */ [ ['tech_name', 'LPF 7833'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'lpf_7833.pdf'], ['Total Solids, %', '67.5'], ['Brookfield Viscosity, cps', '1700'], ['Bound Styrene', 'NA'], ['pH', '10.8'], ['Packaging', '275-gallon composite IBCs, flexitanks, tank trucks, and rail cars'] ],
            /* 3 */ [ ['tech_name', 'LPF 7289'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'lpf_7289.pdf'], ['Total Solids, %', '69.5'], ['Brookfield Viscosity, cps', '1600'], ['Bound Styrene', '23.75'], ['pH', '10.15'], ['Packaging', '275-gallon composite IBCs, flexitanks, tank trucks, and rail cars'] ],
            /* 4 */ [ ['tech_name', 'SB 2108'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'sb_2108.pdf'], ['Total Solids, %', '40.5'], ['Brookfield Viscosity, cps', '20-60'], ['Bound Styrene', '23.5'], ['pH', '11.2'], ['Packaging', '275-gallon composite IBCs, flexitanks, tank trucks, and rail cars'] ]
          ]
        },
        { id: 4,
          prod_name: 'Isoprenic Rubber (IR)',
          prod_desc: 'Natsyn®',
          img_url: 'ir-used-in.jpg',
          seo_desc: 'Goodyear Chemical’s Natsyn is a high cis polyisoprene rubber that has been used in tire compounds, automotive and conveyor belts, hoses, tubes, shoe products, sponge rubber and mechanical and sporting goods.',
          specs: [
            /* 0 */ [ ['tech_name', 'Natsyn® 2200'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'natsyn_2200.pdf'], ['Mooney *Low', '70'], ['Mooney *High', '90'], ['Volatile Matter, %', '<= 0.5'], ['Ash, %', '<= 0.6'], ['Extractables, %', '<= 3.0'], ['Color', 'Opaque to Translucent, White, Yellow, Gray'], ['Max Gel Particles (mm)**', '2.5'], ['Antioxidant', 'BHT'], ['Packaging','Returnable Container, Cardboard Box'] ],
            /* 1 */ [ ['tech_name', 'Natsyn® 2210'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'natsyn_2210.pdf'], ['Mooney *Low', '50'], ['Mooney *High', '70'], ['Volatile Matter, %', '<= 0.5'], ['Ash, %', '<= 0.6'], ['Extractables, %', '<= 3.0'], ['Color', 'Opaque to Translucent, White, Yellow, Gray'], ['Max Gel Particles (mm)**', '2.5'], ['Antioxidant', 'BHT'], ['Packaging','Returnable Container, Cardboard Box'] ],
            /* 2 */ [ ['tech_name', 'Natsyn® 2205'], ['sds_link', '/safety-data-sheets'], ['tds_link', ''], ['Mooney *Low', '70'], ['Mooney *High', '90'], ['Volatile Matter, %', '<= 0.5'], ['Ash, %', '<= 0.6'], ['Extractables, %', '<= 3.0'], ['Color', 'Opaque to Translucent, White, Yellow, Gray'], ['Max Gel Particles (mm)**', '1.0'], ['Antioxidant', 'BHT'], ['Packaging', 'Cardboard Box'] ],
            /* 3 */ [ ['tech_name', 'Natsyn® RNS 7597'], ['sds_link', '/safety-data-sheets'], ['tds_link', ''], ['Mooney *Low', '68'], ['Mooney *High', '80'], ['Volatile Matter, %', '<= 0.5'], ['Ash, %', '<= 0.6'], ['Extractables, %', '<= 3.0'], ['Color', 'Opaque to Translucent, White, Yellow, Gray'], ['Max Gel Particles (mm)**', '2.5'], ['Antioxidant', 'Non-staining, Multifunctional Phenolic'], ['Packaging','Cardboard Box'] ],
            /* 4 */ [ ['tech_name', 'Natsyn® RNS 5726A'], ['sds_link', '/safety-data-sheets'], ['tds_link', ''], ['Mooney *Low', '75'], ['Mooney *High', '85'], ['Volatile Matter, %', '<= 0.5'], ['Ash, %', '<= 0.6'], ['Extractables, %', '<= 3.0'], ['Color', 'Opaque to Translucent, White, Yellow, Gray'], ['Max Gel Particles (mm)**', '2.5'], ['Antioxidant', 'BHT'], ['Packaging','Returnable Container, Cardboard Box'] ]
          ]
        },
        { id: 5,
          prod_name: 'Polybutadiene Rubber (BR)',
          prod_desc: 'BUDENE®',
          img_url: 'br-used-in.jpg',
          seo_desc: 'Butadiene rubber from Goodyear Chemical is a synthetic rubber that has been used in tire compounds, automotive and conveyor belts, hoses, tubes, shoe products, sponge rubber and mechanical and sporting goods.',
          specs: [
            /* 0 */ [ ['tech_name', 'BUDENE® 1207'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1207_1208.pdf'], ['Mooney Viscosity', '55'], ['Catalyst', 'Nickel'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-109'], ['Stablizer', 'Non Staining'], ['Color', 'Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 1 */ [ ['tech_name', 'BUDENE® 1208'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1207_1208.pdf'], ['Mooney Viscosity', '46'], ['Catalyst', 'Nickel'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-109'], ['Stablizer', 'Non Staining'], ['Color', 'Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 2 */ [ ['tech_name', 'BUDENE® 1208G'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1208G.pdf'], ['Mooney Viscosity', '46'], ['Catalyst', 'Nickel'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-109'], ['Stablizer', 'Non Staining'], ['Color', 'Off White to Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 3 */ [ ['tech_name', 'BUDENE® 1208W'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1208W.pdf'], ['Mooney Viscosity', '46'], ['Catalyst', 'Nickel'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-109'], ['Stablizer', 'Non Staining'], ['Color', 'White'], ['Packaging', 'Returnable Container'] ],
            /* 4 */ [ ['tech_name', 'BUDENE® 1280'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1280.pdf'], ['Mooney Viscosity', '40'], ['Catalyst', 'Nickel'], ['Cis 1,4, Content, %', '96'], ['Onset Tg, °C', '-109'], ['Stablizer', 'Non staining slightly discoloring'], ['Color', 'Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 5 */ [ ['tech_name', 'BUDENE® 1222'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1222.pdf'], ['Mooney Viscosity', '63'], ['Catalyst', 'Neodymium'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-110'], ['Stablizer', 'Non Staining'], ['Color', 'White to Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 6 */ [ ['tech_name', 'BUDENE® 1223'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1223.pdf'], ['Mooney Viscosity', '55'], ['Catalyst', 'Neodymium'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-110'], ['Stablizer', 'Non Staining'], ['Color', 'White to Light Amber'], ['Packaging', 'Returnable Container'] ],
            /* 7 */ [ ['tech_name', 'BUDENE® 1224'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'budene_1224.pdf'], ['Mooney Viscosity', '44'], ['Catalyst', 'Neodymium'], ['Cis 1,4, Content, %', '97'], ['Onset Tg, °C', '-110'], ['Stablizer', 'Non Staining'], ['Color', 'White to Light Amber'], ['Packaging', 'Returnable Container'] ]
          ]
        },
        { id: 6,
          prod_name: 'Solution Styrene Butadiene Rubber (SSBR)',
          prod_desc: 'SLF®',
          img_url: 'ssbr-used-in.jpg',
          seo_desc: 'Goodyear Chemical’s Solflex family of solution styrene-butadiene elastomers have been used in tire compounds, automotive and conveyor belts, hoses, tubes, shoe products, sponge rubber and mechanical and sporting goods.',
          specs: [
            /* 0 */ [ ['tech_name', 'SLF® 30H41'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'solflex_30H41.pdf'], ['Mooney Viscosity', '55'], ['Vinyl Content, %', '41'], ['Bound Styrene, %', '30'], ['Onset Tg polymer, °C', '-22'], ['Oil Level, %', '27.3 (Heavy Naphthenic)'], ['Color', 'Yellow to Amber'], ['Packaging', 'Returnable Container'] ],
            /* 1 */ [ ['tech_name', 'SLF® 33H23'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'solflex_33H23.pdf'], ['Mooney Viscosity', '60'], ['Vinyl Content, %', '23'], ['Bound Styrene, %', '33'], ['Onset Tg polymer, °C', '-35'], ['Oil Level, %', '27.3 (Heavy Naphthenic)'], ['Color', 'Yellow to Amber'], ['Packaging', 'Returnable Container'] ],
            /* 2 */ [ ['tech_name', 'SLF® 16S42'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'solflex_16S42.pdf'], ['Mooney Viscosity', '76'], ['Vinyl Content, %', '42'], ['Bound Styrene, %', '16'], ['Onset Tg polymer, °C', '-42'], ['Oil Level, %', '0'], ['Color', 'White'], ['Packaging', 'Returnable Container'] ],
            /* 3 */ [ ['tech_name', 'SLF® 18B10'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'solflex_18B10.pdf'], ['Mooney Viscosity', '70'], ['Vinyl Content, %', '10'], ['Bound Styrene, %', '18'], ['Onset Tg polymer, °C', '-78'], ['Oil Level, %', '0'], ['Color', 'Off White'], ['Packaging', 'Returnable Container'] ]
          ]
        },
        { id: 7,
          prod_name: 'Specialty Chemicals',
          prod_desc: 'PARA, META, HQ',
          img_url: 'specialty-chemicals-used-in.jpg',
          seo_desc: 'META DIPB, PARA DIPB and Hydroquinone from Goodyear Chemical are part of a series of unique, aromatic, organic compounds that have been used as intermediaries.',
          specs: [
            /* 0 */ [ ['tech_name', 'Hydroquinone'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'hydroquinone.pdf'], ['Purity, %', '98.5'], ['Appearance', 'Beige Solid'] ],
            /* 1 */ [ ['tech_name', 'Meta DIPB'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'meta_dipb.pdf'], ['META + PARA Purity, %', '97.1'], ['Appearance', 'Clear Liquid'] ],
            /* 2 */ [ ['tech_name', 'Para DIPB'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'para_dipb.pdf'], ['Purity, %', '98.7'], ['Appearance', 'Clear Liquid'] ]
          ]
        },
        { id: 8,
          prod_name: 'Dicyclopentadienes (DCPD)',
          prod_desc: 'WINGFLEX®',
          img_url: 'dcpd-used-in.jpg',
          seo_desc: 'Goodyear Chemical offers Ultra High Purity and High Purity grades of dicyclopentadiene which has been used in applications such as EPDM Comonomer, jet/rocket fuels, seed and fertilizer coating, specialty chemicals intermediate, polymer additives, specialty monomer and fine chemical production.',
          specs: [
            /* 0 */ [ ['tech_name', 'WINGFLEX® 85'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'wingflex_85.pdf'], ['Purity, %', '83-87'], ['Appearance', 'Clear / Free'] ],
            /* 2 */ [ ['tech_name', 'WINGFLEX® 97'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'wingflex_97.pdf'], ['Purity, %', '97'], ['Appearance', 'Clear Liquid'] ],
            /* 3 */ [ ['tech_name', 'WINGFLEX® 99'], ['sds_link', '/safety-data-sheets'], ['tds_link', 'wingflex_99.pdf'], ['Purity, %', '99'], ['Appearance', 'Clear Liquid'] ]
          ]
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.learn-more {
  margin: 10px 5px;
  padding: 10px;
  border-top: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
}

a.learn-more-btn {
  text-align: center;
  color: #FFF;
  /* padding: 10px 30px; */
  border: 2px solid #274ba7;
  background-color: #274ba7;
  border-radius: 30px;
  padding: 10px 30px;
  min-width: 100%;
  display: block;
}
a.learn-more-btn:hover {
  border: 2px solid #fd0;
  background-color: #fd0;
  color: #000;
  text-decoration: none;
}

.icon {
    width: 42px;
    height: auto;
    /* scale: 4; */
    text-align: center;
    margin: 6px;
}
.icon-gray-fill {
  color: rgb(240, 240, 240);
}

.prod-details .detail-info {
    margin-bottom: 15px;
    min-height: 250px;
}
.prod-details .detail-info h1 {
    margin-top: 15px;
    color: #274ba7;
    text-transform: capitalize;
    font-weight: 700;
}
.prod-details .detail-info h2 {
    font-size: 1.5em;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    color: #999;
    margin-bottom: 35px;
}
.prod-details .detail-info img {
    float: right;
    margin-left: 5px;
    margin-bottom: 15px;
}
.prod-details .details-section .btn {
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-weight: bold;
    font-size: 20px;
    background-color: #1e468d;
    background-image: none;
    color: #fff;
    text-align: left;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
}
.prod-details .details-section .btn:hover {
    color: #fff;
    text-decoration: underline;
}
.prod-details .details-section .btn-info:hover {
    background-color: #325ecf;
    border-color: #1e468d;
}
.prod-details .details-section .btn-info:not(:disabled):not(.disabled):active,
.prod-details .details-section .btn-info:not(:disabled):not(.disabled).active,
.prod-details .details-section .show > .btn-info.dropdown-toggle {
    background-color: #1e468d;
    border-color: #1e468d;
}
.prod-details .details-section .card .card-body {
    background-color: #f6f6f6;
    background-repeat: repeat;
    font-size: 18px;
}
.prod-details .gy-table-row {
    margin-bottom: 5px;
    border-bottom: 2px solid #999;
    padding: 0;
    overflow: auto;
}
.prod-details .gy-table-row {
    padding: 10px 0;
}
.prod-details .gy-table-row .specs-table {
    border-bottom: 1px solid #ccc;
    color: #696969;
    overflow: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
.prod-details .gy-table-row .specs-table .specs-title {
    float: left;
}
.prod-details .gy-table-row .specs-table .specs-description {
    float: right;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
}
.prod-details .gy-table-row .specs-table .specs-description.first {
    background-color: #fd0;
}
.prod-details .gy-table-row .specs-table .specs-description a {
    font-weight: normal;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
}

@media (max-width: 768px) {
    .card-body {
        text-align: center;
    }
    .prod-details .detail-info img {
      max-width: 40%;
    }
}
@media (min-width: 768px) {
    .prod-details .detail-info {
      min-height: 300px;
    }
    .prod-details .gy-table-row .first-list {
        padding-right: 28px;
    }
}
</style>
