<template>
    <main class="wingflex-main">
        <vue-headful title="Wingflex DCPD | Goodyear Chemical" description="Explore Goodyear's top DCPD solutions with Wingflex®: industry-leading purity & reliability in products like Wingflex®99 & Wingflex®90."/>
        <div class="page-background"></div>
        <div class="masthead">
            <div class="text-content">
                <h1>Wingflex&#174;</h1>
            </div>
        </div>
            <b-container class="page-content" fluid>
                <b-row align-v="center" class="text-center">
                    <b-col sm="12" offset-sm="0" md="6" offset-md="0" class="col-xs-12" align-self="center">
                        <img class="img-fluid main-description" src="../../assets/images/wingflex/wingflex-cleaning supplies xs.jpg"
                            alt="Cleaning Supplies" />
                    </b-col>
                    <b-col sm="12" md="6" class="col-xs-12">
                        <p class="description">
                            Goodyear is dedicated to providing high-quality dicyclopentadiene (DCPD) products under the Wingflex&#174; brand name. As a leading domestic supplier in the industry, we take pride in offering a range of DCPD solutions to meet your specific needs. Below, you will find detailed information about our products, quality and reliability assurance, various applications, logistics, and how to get in touch with us.
                        </p>
                    </b-col>
                </b-row>
            </b-container>
            <b-container class="prod-section" fluid>
                <b-row align-v="center" align-h="center">
                    <b-col class="col-xs-12" sm="12" md="6" lg="6" xl="6">
                        <h2>Products</h2>
                        <p>
                            At Goodyear, we can offer a comprehensive selection of DCPD products that are renowned for their purity and reliability, with the major market grades highlighted below.
                        </p>
                        <ol>
                            <li>
                                <strong class="brand-name">Wingflex®99 Ultra High Purity Dicyclopentadiene:</strong> Made using
                                Goodyear's proprietary technology, the ultra-high-quality 99% pure DCPD with low moisture and
                                peroxide values is an ideal chemical compound for applications requiring unsurpassed product
                                quality and consistency. <br>
                                <a href="/docs/tds/wingflex_99.pdf" target="_blank">Technical Data Sheet&#160;&gt;</a>
                            </li>

                            <li>
                                <strong class="brand-name">Wingflex®97 mid-Ultra High Purity Dicyclopentadiene:</strong> The
                                next purest offering in our portfolio, Wingflex®97 provides the industry with the purity ideally
                                suited for specialized applications such as monomer or antioxidant production. <br>
                                <a href="/docs/tds/wingflex_97.pdf" target="_blank">Technical Data Sheet&#160;&gt;</a>
                            </li>

                            <li>
                                <strong class="brand-name">Wingflex®85 Mid High Purity Dicyclopentadiene:</strong> Designed with
                                the unsaturated polyester resin industry in mind, this DCPD grade offers a reliable performance
                                combined excellent consistency. <br>
                                <a href="/docs/tds/wingflex_85.pdf" target="_blank">Technical Data Sheet&#160;&gt;</a>
                            </li>
                            <li>
                                <strong class="brand-name">DCPD Blends:</strong> We also provide DCPD blends, custom-tailored
                                mixtures of DCPD and other compatible materials, offering a convenient and cost-effective
                                solution for your applications. <br>
                            </li>
                        </ol>
                    </b-col>
                    <b-col class="col-xs-12 text-center" sm="12" md="6" lg="6" xl="6">
                        <img class="img-fluid" src="../../assets/images/wingflex/wingflex-products.jpg" alt="Lab technician pouring liquid" />
                    </b-col>
                </b-row>
            </b-container>
            <b-container class="page-content" fluid>
                <b-row class="section-container">
                    <div class="col-12 align-self-center text-center">
                        <h2>Applications</h2>
                    </div>
                    <div class="container-fluid">
                        <div class="row align-items-center justify-content-center no-gutters mt-4 text-center">
                            <div class="card-deck">
                                <div class="cols-12 col-xs-12 col-sm-6 offset-sm-0 col-md-6 offset-md-0 col-lg-4 offset-lg-2 col-xl-2 offset-xl-1 mt-4">
                                    <div class="card w-100 h-100">
                                        <img class="card-img-top" src="../../assets/images/wingflex/wingflex-polymer-manufacturing.jpg" alt="Wind turbine farm"/>
                                        <div class="card-body">
                                            <h3 class="card-title">Polymer Manufacturing</h3>
                                            <p class="card-text">DCPD is a crucial ingredient in the production of high-performance polymers, such as polyDCPD engineering plastics, EPDM rubber and unsaturated polyester resins.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cols-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 offset-xl-0 mt-4">
                                    <div class="card w-100 h-100">
                                        <img class="card-img-top" src="../../assets/images/wingflex/wingflex-adhesives and sealants.jpg" alt="Packing tape & bubble wrap">
                                        <div class="card-body">
                                            <h3 class="card-title">Adhesives and Sealants</h3>
                                            <p class="card-text">DCPD is widely used in formulating adhesives and sealants due to Its excellent bonding properties and chemical resistance.</p>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div>
                        </div>
                        <div class="row align-items-center justify-content-center no-gutters">
                            <div class="card-deck"> -->
                                <div class="cols-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 offset-lg-0 col-xl-2 offset-xl-0 mt-4">
                                    <div class="card w-100 h-100">
                                        <img class="card-img-top" src="../../assets/images/wingflex/wingflex-coatings and paints.jpg" alt="Paint can & paint brush">
                                        <div class="card-body">
                                            <h3 class="card-title">Coatings and Paints</h3>
                                            <p class="card-text">DCPD is utilized in the manufacture of high-quality coatings and paints adding to, durability and corrosion resistance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cols-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 offset-xl-0 mt-4">
                                    <div class="card w-100 h-100">
                                        <img class="card-img-top" src="../../assets/images/wingflex/wingflex-fuel additives.jpg" alt="Filling a tank with gasoline">
                                        <div class="card-body">
                                            <h3 class="card-title">Fuel Additives</h3>
                                            <p class="card-text">DCPD is used as an additive in fuels to enhance their performance and stability.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cols-12 col-xs-12 col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-4 offset-lg-0 col-xl-2 offset-xl-0 mt-4">
                                    <div class="card w-100 h-100">
                                        <img class="card-img-top" src="../../assets/images/wingflex/wingflex-electronics.jpg" alt="Circuit board with chip">
                                        <div class="card-body">
                                            <h3 class="card-title">Electronics</h3>
                                            <p class="card-text">DCPD is employed in the electronics industry for encapsulation and potting applications due to its exceptional electrical insulation properties.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-container>
            <b-container class="page-content" fluid>
                <b-row class=" text-center section-container">
                    <b-col md="12">
                        <h2>Quality and Reliability</h2>
                    </b-col>
                    <b-col sm="12" md="12" lg="12" xl="4" align-self="center">
                        <img class="img-fluid" src="../../assets/images/wingflex/wingflex-quality and reliability sm2.jpg"
                            alt="Pipette adding chemicals" />
                    </b-col>
                    <b-col sm="12" md="12" lg="12" xl="8">
                        <div class="container-fluid">
                            <div class="row align-items-center justify-content-center no-gutters mt-4">
                                <!-- <div class="card-wrapper"> -->
                                    <div class="card-deck">
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="clipboard-check" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Consistent Quality</h3>
                                                    <p class="card-text">We maintain stringent quality control measures throughout our manufacturing processes to have consistent product quality. Every batch of DCPD made at our Beaumont, TX facility by skilled professionals meets or exceeds industry standards.</p>
                                                    <a href="/certifications">Link to our plant certifications&#160;&gt;</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="truck" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Reliable Supply Chain</h3>
                                                    <p class="card-text">We have established strong and diversified relationships with trusted suppliers creating  a reliable supply of raw materials. This, combined with our efficient production capabilities, enables us to timely meet your demands and fulfill orders.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="people-fill" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Experienced Team</h3>
                                                    <p class="card-text">Our team consists of experienced professionals. They are dedicated to exceptional service and support, providing information to help you make informed decisions to find the best solutions for your specific requirements.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="clock-fill" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Timely Delivery</h3>
                                                    <p class="card-text">We focus on  prompt delivery of your orders. Our logistics team works closely with reliable shipping partners to get you the products in a timely manner, regardless of your location. We track shipments to provide you with real-time updates and work to minimize any potential delays.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="headset" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Responsive Customer Support</h3>
                                                    <p class="card-text">We pride ourselves on excellent customer service. Our knowledgeable and friendly customer support team is readily available to address any questions or concerns you may have. We strive to provide quick and effective solutions for a smooth and hassle-free experience for our customers.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div class="card h-100 w-100 border-0 align-items-center">
                                                <b-iconstack class="icon">
                                                    <b-icon stacked icon="circle-fill" class="icon-gray-fill"></b-icon>
                                                    <b-icon stacked icon="stars" scale="0.60"></b-icon>
                                                </b-iconstack>
                                                <div class="card-body">
                                                    <h3 class="card-title">Long-Standing Reputation</h3>
                                                    <p class="card-text">With years of experience in the chemical industry, we have built a strong reputation for reliability and professionalism. Our satisfied customers across various sectors trust us for their DCPD  needs, helping them achieve their business success.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        <!-- </div> -->
                    </b-col>
                </b-row>
            </b-container>
            <b-container class="prod-section" fluid>
                <b-row>
                    <b-col>
                        <h2>Logistics</h2>
                        <p>We understand the importance of efficient logistics to obtain prompt and reliable delivery of our products. Our dedicated logistics team works closely with trusted shipping partners to obtain timely dispatch and secure transportation of your orders. We offer flexible shipping options to suit your requirements, including:</p>
                        <b-row>
                            <b-col class="icon-box" align-self="center">
                                <svg width="80" height="121" viewBox="0 0 80 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="11" width="76" height="84" rx="12" stroke="white" stroke-width="4"/>
                                    <rect x="10" y="27" width="60" height="24" rx="5" stroke="white" stroke-width="4"/>
                                    <line x1="40" y1="28" x2="40" y2="50" stroke="white" stroke-width="4"/>
                                    <circle cx="22" cy="19" r="2" fill="white"/>
                                    <circle cx="58" cy="19" r="2" fill="white"/>
                                    <path d="M29 19H51" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M29 2H51" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M19 39.3666L29.7331 34" stroke="white" stroke-linecap="round"/>
                                    <path d="M49 39.3666L59.7331 34" stroke="white" stroke-linecap="round"/>
                                    <path d="M23 40.7332L28.3666 38.0499" stroke="white" stroke-linecap="round"/>
                                    <path d="M53 40.7332L58.3666 38.0499" stroke="white" stroke-linecap="round"/>
                                    <path d="M51 2L60 11" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M29 2L20 11" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M6 119L18 96" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M74 119L62 96" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M4 87H76" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M16 103H65" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M10 115H71" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M30 65H50" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M34 73H46" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <circle cx="16" cy="69" r="6" stroke="white" stroke-width="4"/>
                                    <circle cx="64" cy="69" r="6" stroke="white" stroke-width="4"/>
                                </svg>
                                <p class="caption">Rail</p>
                            </b-col>
                            <b-col class="icon-box" align-self="center">
                                <!-- <b-icon icon="truck-flatbed"></b-icon> -->
                                <svg width="151" height="93" viewBox="0 0 151 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M149 64C147 75 140 73.5 130 73.5" stroke="white" stroke-width="4"/>
                                    <circle cx="36.5" cy="75.5" r="15.5" stroke="white" stroke-width="4"/>
                                    <circle cx="115.5" cy="75.5" r="15.5" stroke="white" stroke-width="4"/>
                                    <path d="M108 6L108 61" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M113 6L113 58" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M112.835 9C114.096 2.78983 107.69 -0.61108 108.012 9" stroke="white" stroke-width="4"/>
                                    <path d="M109 60L11 60" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M11 60C10.4771 70.2246 12.3604 73.2458 19.5 74" stroke="white" stroke-width="4"/>
                                    <line x1="53" y1="74" x2="99" y2="74" stroke="white" stroke-width="4"/>
                                    <path d="M114 13H130" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M130 13L149 39" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M149 39L149 64" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <line x1="12" y1="8" x2="99" y2="8" stroke="white" stroke-width="4"/>
                                    <line x1="12" y1="56" x2="99" y2="56" stroke="white" stroke-width="4"/>
                                    <path d="M12.5002 7.5C-1.50014 10 0.49997 58.5 12.5002 56" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <path d="M96.7356 7.5C110.736 10 108.736 58.5 96.7356 56" stroke="white" stroke-width="4" stroke-linecap="round"/>
                                    <line x1="19" y1="7" x2="19" stroke="white" stroke-width="4"/>
                                    <line x1="39" y1="7" x2="39" stroke="white" stroke-width="4"/>
                                    <line x1="59" y1="7" x2="59" stroke="white" stroke-width="4"/>
                                    <line x1="79" y1="7" x2="79" stroke="white" stroke-width="4"/>
                                    <line x1="21" y1="2" x2="26" y2="2" stroke="white" stroke-width="4"/>
                                    <line x1="41" y1="2" x2="46" y2="2" stroke="white" stroke-width="4"/>
                                    <line x1="61" y1="2" x2="66" y2="2" stroke="white" stroke-width="4"/>
                                    <line x1="81" y1="2" x2="86" y2="2" stroke="white" stroke-width="4"/>
                                    <line x1="28" y1="-8.74228e-08" x2="28" y2="7" stroke="white" stroke-width="4"/>
                                    <line x1="48" y1="-8.74228e-08" x2="48" y2="7" stroke="white" stroke-width="4"/>
                                    <line x1="68" y1="-8.74228e-08" x2="68" y2="7" stroke="white" stroke-width="4"/>
                                    <line x1="88" y1="-8.74228e-08" x2="88" y2="7" stroke="white" stroke-width="4"/>
                                    <path d="M19 9C19 32.3239 19 51.1507 19 55" stroke="white" stroke-width="2"/>
                                    <path d="M39 7C39 32.3521 39 52.8159 39 57" stroke="white" stroke-width="2"/>
                                    <path d="M59 7C59 32.3521 59 52.8159 59 57" stroke="white" stroke-width="2"/>
                                    <path d="M79 7C79 32.3521 79 52.8159 79 57" stroke="white" stroke-width="2"/>
                                    <line x1="128.832" y1="20.4453" x2="140.832" y2="38.4453" stroke="white" stroke-width="2"/>
                                    <line x1="140" y1="38" x2="118" y2="38" stroke="white" stroke-width="2"/>
                                    <line x1="130" y1="21" x2="117" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="118" y1="39" x2="118" y2="22" stroke="white" stroke-width="2"/>
                                </svg>
                                <p>Truck</p>
                            </b-col>
                            <b-col class="icon-box" align-self="center">
                                <svg width="155" height="114" viewBox="0 0 155 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0.88906" y1="7.00308" x2="90.8891" y2="2.00308" stroke="white" stroke-width="4"/>
                                    <line x1="4.00011" y1="103.021" x2="3.00011" y2="6.02062" stroke="white" stroke-width="4"/>
                                    <line x1="90" x2="90" y2="114" stroke="white" stroke-width="4"/>
                                    <line x1="45" y1="6" x2="45" y2="106" stroke="white" stroke-width="4"/>
                                    <line x1="35" y1="7" x2="35" y2="107" stroke="white" stroke-width="4"/>
                                    <line x1="153" y1="28" x2="153" y2="71" stroke="white" stroke-width="4"/>
                                    <line x1="90.8123" y1="2.17238" x2="153.812" y2="30.1724" stroke="white" stroke-width="4"/>
                                    <line x1="89.8725" y1="112.348" x2="152.873" y2="69.3481" stroke="white" stroke-width="4"/>
                                    <line x1="88.7463" y1="111.984" x2="2.74625" y2="100.984" stroke="white" stroke-width="4"/>
                                    <line x1="148.5" y1="29" x2="148.5" y2="71" stroke="white"/>
                                    <line x1="144.5" y1="27" x2="144.5" y2="73" stroke="white"/>
                                    <line x1="140.5" y1="25" x2="140.5" y2="76" stroke="white"/>
                                    <line x1="136.5" y1="23" x2="136.5" y2="79" stroke="white"/>
                                    <line x1="132.5" y1="22" x2="132.5" y2="82" stroke="white"/>
                                    <line x1="128.5" y1="21" x2="128.5" y2="85" stroke="white"/>
                                    <line x1="124.5" y1="18" x2="124.5" y2="87" stroke="white"/>
                                    <line x1="120.5" y1="15" x2="120.5" y2="90" stroke="white"/>
                                    <line x1="116.5" y1="12" x2="116.5" y2="94" stroke="white"/>
                                    <line x1="112.5" y1="13" x2="112.5" y2="95" stroke="white"/>
                                    <line x1="108.5" y1="11" x2="108.5" y2="99" stroke="white"/>
                                    <line x1="104.5" y1="9" x2="104.5" y2="102" stroke="white"/>
                                    <line x1="100.5" y1="7" x2="100.5" y2="105" stroke="white"/>
                                    <line x1="96.5" y1="5" x2="96.5" y2="107" stroke="white"/>
                                </svg>
                                <p class="caption">Isotainer</p>
                            </b-col>
                        </b-row>
                        <p class="brand-name text-center">All products are manufactured and shipped from a centrally located plant in
                            Beaumont, Texas.</p>
                    </b-col>
                    <b-col md="6" class="text-center" align-self="center">
                        <img class="img-fluid" src="../../assets/images/wingflex/wingflex-logistics-chemical-tanker.jpg" alt="Truck driving through fields" />
                    </b-col>
                </b-row>
            </b-container>
            <b-container class="page-content" fluid>
                <b-row align-h="center" class="contact text-center">
                    <b-col md="6">
                        <h2>Contact Us</h2>
                        <p>
                            We value your interest and are eager to assist you. Should you have any inquiries, require further
                            information, or wish to place an order, please feel free to contact us via the online inquiry form.
                        </p>
                        <p>
                            We look forward to serving you and providing you with top­ notch DCPD products for  your specific needs. Trust Goodyear for all your DCPD needs!
                        </p>
                        <div class="text-center"><a class="btn" href="/contact-us">Online Inquiry Form</a></div>
                    </b-col>
                </b-row>
            </b-container>
    </main>
</template>
<script>
    export default {
        name: 'wingflex'
    }
</script>
<style scoped>
    .card-body a {
        color: #002AFF;
    }

    .text-center {
        text-align: center;
    }

    h1 {
        padding: 10px;
    }

    /* h2 {
        font-size: 40px;
    } */

    .img-fluid {
        width: 100% \9;
    }

    .page-background {
        background-position: top center;
        background-size: cover;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        z-index: -1;
        background-image: url('../../assets/images/wingflex/wingflex-top title bg md.jpg');
        padding: 100px;
    }

    .masthead {
        margin: 200px auto 195px;
    }

    .page-content {
        padding: 75px;
        background-color: #fff;
        width: 100%;
    }

    .inline-image {
        padding: 50px;
    }

    .prod-section {
        /* min-height: 280px; */
        /* text-align: left; */
        color: #fff;
        padding: 75px;
        background: -webkit-gradient(linear, left top, right bottom, from(#163c9b), to(#3272c7));
        background: linear-gradient(to bottom right, #163c9b, #3272c7);
    }

    /* .section-container {
        margin: 20px;
        padding: 25px 50px 25px 50px;
    } */

    .brand-name {
        font-weight: bold;
        font-style: italic;
    }

    .description {
        padding: 20px;
    }
    
    ol li {
        padding: 20px 0;
    }

    ol li a {
        color: #fd0;
        padding: 0 20px 0 0;
    }

    ol li a:hover {
        color: #fd0;
        text-decoration: underline;
    }

    .icon {
        width: 116px;
        height: auto;
        /* scale: 4; */
        text-align: center;
        margin: 6px;
    }

    .icon-box {
        width: 90px;
        height: auto;
        /* display: inline-block; */
        margin: 0 50px;
        text-align: center;
        padding: 20px;
    }

    .icon-box svg {
        width: auto;
        height: 80px;
    }

    .icon-box p {
        font-size: smaller;
    }

    .icon-gray-fill {
        color: rgb(240, 240, 240);
    }

    .card-text {
        font-size: small;
        padding: 0;
    }

    .contact {
        padding: 50px 0;
    }

    /* Media Queries */
    @media (max-width: 445px) {
        .page-background {
            background-image: url('../../assets/images/wingflex/wingflex-top title bg xs.jpg');
        }
        .main-description {
            width: 175px;
        }
        .description {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: 445px) {
        .btn {
            min-width: 250px;
        }
    }

    @media (min-width:446px) and (max-width: 767px) {
        .page-background {
            background-image: url('../../assets/images/wingflex/wingflex-top title bg sm.jpg');
        }
        .main-description {
            width: 225px;
        }
    }

    @media (min-width: 575px) and (max-width: 633px) {
        .card-title {
            overflow-wrap: normal;
            font-size: 1.5rem;
        }
        .card-deck .card {
            min-width: 165px;
        }
        .card-body {
            padding: .5rem;
        }
    }

    @media (min-width: 768px) {
        .promo-section .container {
            padding: 50px 24px;
        }
        .main-description {
            width: 275px;
        }
    }

    @media (min-width: 1038px) {
        .btn {
            margin-top: 30px;
        }
    }

    @media (min-width: 1199px) and (max-width: 1605px) { /* pixels where words are cut off - like overflow-wrap: anywhere; usage */
        .card-title {
            overflow-wrap: normal;
            font-size: 1.5rem;
        }
        .card-deck .card {
            min-width: 165px;
        }
        .card-body {
            padding: .5rem;
        }
    }

    @media (min-width:1281px) and (max-width: 1600px) {
        .page-background {
            background-image: url('../../assets/images/wingflex/wingflex-top title bg.jpg');
        }
    }

    @media (min-width: 1601px) and (max-width: 2500px) {
        .page-background {
            background-image: url('../../assets/images/wingflex/wingflex-top title bg.jpg');
        }
    }
</style>