import axios from 'axios';
const jose = require('jose');
import { config } from '../config';

export default {
    initAuthInterceptor: () => {
        // Add a request interceptor
        axios.interceptors.request.use(
            async function (axiosConfig) {
                let payload = {};

                const accessToken = await new jose.SignJWT(payload)
                    .setProtectedHeader({ alg: 'HS256' })
                    .setExpirationTime(config.JWT_ACCESS_TIMEOUT)
                    .sign(new TextEncoder().encode(
                        config.JWT_ACCESS_SECRET
                    ));

                axiosConfig.headers['x-custom-authorization'] = 'Bearer ' + accessToken;
                return axiosConfig;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }
}