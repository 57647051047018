<template>
  <div class="contact">
    <vue-headful title="Contact Us | Goodyear Chemical" description="Contact the Goodyear Chemical rubber experts. We have the technical knowledge to innovate, engineer and solve the toughest product challenges."/>
    <b-container fluid>
      <div class="section-background"></div>
      <b-row>
        <b-col class="container" sm="12" md="9">
          <b-form id="contactUs" class="contactUs" name="contactUs" @submit="submitContact" v-if="showForm">
            <div class="form-header">
              <h1>Contact Us</h1>
              <p>Please submit the form below for customer inquiries. A Goodyear Chemical representative will respond to your message. <br/></p>
            </div>

            <b-form-group>
              <label for="name">Name</label>
              <b-form-input id="name" v-model="form.name" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="email">Email address</label>
              <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="phoneNumber">Phone number</label>
              <b-form-input id="phoneNumber" v-model="form.phoneNumber" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="companyName">Company Name</label>
              <b-form-input id="companyName" v-model="form.companyName" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="companyAddress">Company Address</label>
              <b-form-input id="companyAddress" v-model="form.companyAddress" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="subject">Subject</label>
              <b-form-select id="subject" v-model="form.subject" :options="subjectCategories" type="text" required>
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="comments">How can we help you?</label>
              <b-form-textarea id="comments" v-model="form.comments" rows="3" required></b-form-textarea>
            </b-form-group>

            <b-form-group>
              <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey" ></vue-recaptcha>
              <p class="red">{{this.invalidField}}</p>
              <b-button type="submit" variant="primary" v-if="!loading">Contact Now</b-button>
              <b-spinner label="Loading..." v-if="loading"></b-spinner>
            </b-form-group>
          </b-form>

          <div class="gy-form-ty" v-if="showThanks && !showForm">
            <h2>Thank You</h2>
            <p>Your submission has been sent. A Goodyear Chemical representative will respond to your message. </p>
          </div>

          <div class="gy-form-fail" v-if="showError && !showForm">
            <h2>An error has occurred</h2>
            <p>Please try again later. </p>
          </div>
        </b-col>
        <b-col class="container contacts" sm="12" md="3">
            <b-col class="other-contact" md="12" sm="6">
              <h4>Our Locations</h4>
            </b-col>

            <b-col class="other-contact" md="12" sm="6">
              <h4>Akron R&D Facility</h4>
              <span>1485 E Archwood Ave</span><br/>
              <span>Akron, OH 44306</span><br/>
            </b-col>

            <b-col class="other-contact" md="12" sm="6">
              <h4>Bayport Plant</h4>
              <span>13441 Bay Area Bouvard</span><br/>
              <span>Pasadena, Texas 77507</span><br/>
            </b-col>

            <b-col class="other-contact" md="12" sm="6">
              <h4>Beaumont Plant</h4>
              <span>11357 IH 10 @ Smith Road</span><br/>
              <span>Beaumont, Texas 77705 </span><br/>
            </b-col>

            <b-col class="other-contact" md="12" sm="6">
              <h4>Houston Plant</h4>
              <span>2000 Goodyear Drive</span><br/>
              <span>Houston, Texas 77017</span><br/>
            </b-col>

            <b-col class="other-contact" md="12" sm="6">
                <h4>Niagara Falls Plant</h4>
              <span>5500 Goodyear Drive </span><br/>
              <span>Niagara Falls, New York 14304 </span><br/>
            </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import { config } from '../config';

window.dataLayer = window.dataLayer || []

export default {
  name: 'Contact',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        companyAddress: '',
        subject: null,
        comments: '',
        gResponse: '',
      },
      subjectCategories: [
        'Antioxidants', 'Business Development', 'C5 Based Materials, Isoprene Monomer, Isoprenic Rubber (IR)',
        'Dicyclopentadienes (DCPD)', 'Emulsion Styrene Butadiene Rubber (ESBR)', 'Food Grade (ESBR)',
        'High Solids Latex (ESBR)', 'Polybutadiene Rubber (BR)', 'R&D', 'Sales',
        'Solution Styrene Butadiene Rubber (SSBR)', 'Specialty Chemicals', 'Technology', 'Others', 'SDS Request', 'Regulatory Form Request', 'Conflict Minerals'
      ],
      showForm: true,
      showThanks: false,
      showError: false,
      loading: false,
      invalidField: ' ',
    }
  },
  computed: {
    recaptchaClientKey: function () {
      return config.RECAPTCHA_CLIENT_KEY;
    }
  },
  mounted () {
    this.subjectCategories.sort()
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        this.invalidField = ' ';
        this.form.gResponse = response;
      } 
    },
    submitContact (evt) {
      evt.preventDefault()
      console.log(this.form)

      // recaptcha
      if (this.form.gResponse.length === 0) {
        // reCaptcha not verified
        this.loading = false
        this.invalidField = 'Please verify using reCAPTCHA.'
        return false;
      }

      axios.post('/api/sendContactUs', JSON.stringify({
        name: this.form.name,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        companyName: this.form.companyName,
        companyAddress: this.form.companyAddress,
        subject: this.form.subject,
        comments: this.form.comments,
        gResponse: this.form.gResponse
      }))
        .then(response => {
          console.log(response.data)
          this.showForm = false
          if (response.data.code === 0) {
            this.showThanks = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Contact Us' })
          } else {
            this.showError = true
          }
        })
        .catch( (error) => {
          console.log(error)
          this.showForm = false;
          this.showError = true;
        })
    }
  },
  components: {
    VueRecaptcha,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact {
  background-color: #ccc;
  background-image: url('../assets/images/diagonal-lines-two.png');
  background-repeat: repeat;
}
.container {
  background-color: #fff;
  min-height: 500px;
}
.form-header h1 {
  color: #274ba7;
  font-size: 42px;
  margin-bottom: 0px;
  font-weight: 700;
}
select {
  border-radius: 0px;
}
.btn {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 35px;
}
.other-contact {
  margin-top: 10px;
  margin-bottom: 25px;
  float: left;
}
.other-contact h4 {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .container.contacts {
    padding-top: 25px;
  }
}
@media (min-width: 768px) {
  .container {
    padding: 40px;
    min-height: 635px;
  }
  .btn {
    width: 250px;
  }
}
@media (max-width: 810px) {
  .container.contacts {
    min-height: 100px;
  }
}
@media (min-width: 810px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width:992px) {
  .container {
    min-height: 500px;
  }
}
@media (max-width: 1220px) {
  .container.contacts {
    background-color: #f1f1f1;
  }
}
</style>
