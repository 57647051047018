<template>
  <div class="products">
    <vue-headful title="Our Products | Goodyear Chemical" description="Learn about the wide range of products Goodyear Chemical offers."/>
    <div class="page-background"></div>
    <section class="main-section">
      <b-container fluid>
        <b-row>
          <b-col>
            <div class="masthead">
              <div class="masthead-icon">
                <div class="icon-container">
                    <img class="masthead-img" src="../../assets/images/goodyear-masthead.png" alt="Goodyear Logo"/>
                </div>
              </div>
              <div class="text-content">
                <h1>Find Our Products</h1>
                <p>Grounded in technical expertise and innovation</p>
                <router-link class="btn" to="/request-sample">Request Sample</router-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <div class="separator"></div>
    <section class="tiles-container">
     <b-container>
      <b-row>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/polybutadiene-rubber"><h4>Polybutadiene Rubber (BR) </h4><p>BUDENE®</p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/solution-styrene-butadiene-rubber"><h4>Solution Styrene Butadiene Rubber (SSBR)</h4><p> SLF®</p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/emulsion-styrene-butadiene-rubber"><h4>Emulsion Styrene Butadiene Rubber (ESBR)</h4><p> PLIOFLEX®</p></router-link></div></div>
      </b-row>
      <b-row>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/food-grade"><h4>Food Grade (ESBR) </h4><p> PLIOGUM®</p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/high-solids-latex"><h4>High Solids Latex (ESBR) </h4><p> </p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/isoprenic-rubber"><h4>Isoprenic Rubber (IR)</h4><p>Natsyn® </p></router-link></div></div>
      </b-row>
      <b-row>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/dicyclopentadienes"><h4>Dicyclopentadienes (DCPD)</h4><p>WINGFLEX®</p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/antioxidants"><h4>Antioxidants  </h4><p>POLYSTAY®</p></router-link></div></div>
        <div class="col-sm-12 col-md-4"><div class="gy-box"><hr><router-link to="/products/specialty-chemicals"><h4>Specialty Chemicals</h4><p>PARA, META, HQ</p></router-link></div></div>
      </b-row>
     </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Products'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-background {
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  z-index: -1;
  background-image: url('../../assets/images/background/archwood_research2-md.jpg');
}
/*.container {
  padding: 24px;
}*/
.main-section {
  min-height: 250px;
}
.masthead {
  margin-bottom: 70px;
}
.masthead h1 {
  font-size: 32px;
  color: #6d6e71;
  border-bottom: none;
}
.tiles-container {
  margin-bottom: 25px;
}
.tiles-container .row>div {
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 10px;
}
.tiles-container .gy-box {
  background-color: rgba(255,255,255,0.88);
  border-bottom: 3px solid #fd0;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 auto;
}
.tiles-container hr {
  background-color: rgba(255,255,255,0.2);
  background-repeat: repeat;
  background-image: url('../../assets/images/diagonal.png');
  border: none;
  margin: -10px 0 0;
  height: 10px;
}
.tiles-container a {
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  min-height: 100px;
  position: relative;
}
.tiles-container .gy-box:hover, .tiles-container .gy-box a:hover {
  text-decoration: none;
  background-color:rgb(217,217,217);
}
.tiles-container h4 {
  color: #274ba7;
  font-size: 22px;
  margin: 10px 0;
  padding-right: 25px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
}
.tiles-container p {
  color: #333;
  font-size: 18px;
  line-height: 140%;
  margin: 0;
  padding-right: 25px;
}
.tiles-container span {
  color: #274ba7;
  font-size: 2.6em;
  font-weight: normal;
  margin: 0;
  position: absolute;
  top: 35%;
  right: -2px;
}

@media (max-width: 445px) {
  .page-background {
    background-image: url('../../assets/images/background/archwood_research2-xs.jpg');
  }
}
@media (min-width:446px) and (max-width: 768px) {
  .page-background {
    background-image: url('../../assets/images/background/archwood_research2-sm.jpg');
  }
}
@media (max-width: 768px) {
  .masthead {
    margin: 75px auto 50px;
  }
  .tiles-container .gy-box {
    margin-top: 0px;
    box-shadow: 0 5px 5px -3px #333;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .tiles-container .gy-box {
    min-height: 200px;
  }
}
@media (min-width: 768px) {
  .tiles-container .gy-box {
    margin-top: 0px;
    box-shadow: 0 5px 5px -3px #333;
    max-width: 80%;
  }
}
@media (min-width:1024px) {
  .tiles-container .gy-box {
    height: 140px;
  }
}
@media (min-width:1281px) and (max-width: 1600px) {
  .page-background {
    background-image: url('../../assets/images/background/archwood_research2-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px) {
  .page-background {
    background-image: url('../../assets/images/background/archwood_research2-xl.jpg');
  }
}
</style>
