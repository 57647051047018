// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@babel/polyfill'
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vueHeadful from 'vue-headful'
import App from './App'
import router from './router'
import authInterceptor from './interceptors/auth.interceptor'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

authInterceptor.initAuthInterceptor()

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('vue-headful', vueHeadful)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
