<template>
  <div class="sample-request">
    <vue-headful title="Request Product Sample | Goodyear Chemical" description="Get the durability, quality, performance and appearance you need by requesting a sample from Goodyear Chemical today. "/>
    <div class="container">
      <b-row>
        <b-col sm="12">
          <b-form id="contactUs" class="contactUs" name="contactUs" @submit="submitSampleRequest" v-if="showForm">
            <div class="form-header">
              <h1>Request a Goodyear Chemical Sample</h1>
              <p>To submit a sample request, please complete and submit the form below. A business manager will review this request and confirm the sample shipment. <br/></p>
            </div>

            <b-form-group class="col-sm-12">
              <h3>Contact Information</h3>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="name">Name</label>
              <b-form-input id="name" v-model="form.name" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="phoneNumber">Phone number</label>
              <b-form-input id="phoneNumber" v-model="form.phoneNumber" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-12">
              <label for="email">Email address</label>
              <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12">
              <h3>Ship To </h3>
            </b-form-group>

           <b-form-group class="col-sm-12 col-md-6">
              <label for="companyName">Company name</label>
              <b-form-input id="companyName" v-model="form.companyName" type="text" required></b-form-input>
           </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="companyAddress">Address</label>
              <b-form-input id="companyAddress" v-model="form.companyAddress" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="companyCity">City</label>
              <b-form-input id="companyCity" v-model="form.companyCity" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="companyState">State</label>
              <b-form-input id="companyState" v-model="form.companyState" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="companyZipPost">Zip/Postal code</label>
              <b-form-input id="companyZipPost" v-model="form.companyZipPost" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="companyCountry">Country</label>
              <b-form-input id="companyCountry" v-model="form.companyCountry" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12">
              <h3>Product Information </h3>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="requestingProduct">Requesting product</label>
              <b-form-input id="requestingProduct" v-model="form.requestingProduct" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="endUseForProduct">What is your end use for this product?</label>
              <b-form-input id="endUseForProduct" v-model="form.endUseForProduct" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="annualPotential">What is your annual potential volume for this product?</label>
              <b-form-input id="annualPotential" v-model="form.annualPotential" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="sampleVolume">Sample volume</label>
              <b-form-input id="sampleVolume" v-model="form.sampleVolume" type="text" placeholder="For samples of 3 bales of rubber or 10 gallons of latex or less" required></b-form-input>
            </b-form-group>

            <b-form-group class="form-buttons col-sm-12">
              <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey" ></vue-recaptcha>
              <p class="red">{{this.invalidField}}</p>
              <b-button type="submit" variant="primary" v-if="!loading">Request Sample</b-button>
              <b-spinner label="Loading..." v-if="loading"></b-spinner>
            </b-form-group>

          </b-form>

          <div class="gy-form-ty" v-if="showThanks && !showForm">
            <h2>Thank You</h2>
            <p>Your submission has been sent. A Goodyear Chemical representative will respond to your message. </p>
          </div>

          <div class="gy-form-fail" v-if="showError && !showForm">
            <h2>An error has occurred</h2>
            <p>Please try again later. </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import { config } from '../config';

window.dataLayer = window.dataLayer || []

export default {
  name: 'Sample-request',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        companyAddress: '',
        companyCity: '',
        companyState: '',
        companyZipPost: '',
        companyCountry: '',
        requestingProduct: '',
        endUseForProduct: '',
        annualPotential: '',
        sampleVolume: '',
        gResponse: '',
      },
      showForm: true,
      showThanks: false,
      showError: false,
      loading: false,
      invalidField: ' ',
    }
  },
  computed: {
    recaptchaClientKey: function () {
      return config.RECAPTCHA_CLIENT_KEY;
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        this.invalidField = ' ';
        this.form.gResponse = response;
      }
    },
    submitSampleRequest (evt) {
      evt.preventDefault();

      // recaptcha
      if (this.form.gResponse.length === 0) {
        // reCaptcha not verified
        this.loading = false
        this.invalidField = 'Please verify using reCAPTCHA.'
        return false;
      }

      axios.post('/api/sendSampleRequest', {
        name: this.form.name,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        companyName: this.form.companyName,
        companyAddress: this.form.companyAddress,
        companyCity: this.form.companyCity,
        companyState: this.form.companyState,
        companyZipPost: this.form.companyZipPost,
        companyCountry: this.form.companyCountry,
        requestingProduct: this.form.requestingProduct,
        endUseForProduct: this.form.endUseForProduct,
        annualPotential: this.form.annualPotential,
        sampleVolume: this.form.sampleVolume,
        gResponse: this.form.gResponse
      })
        .then(response => {
          console.log(response.data)
          this.showForm = false
          if (response.data.code === 0) {
            this.showThanks = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Sample Request' })
          } else {
            this.showError = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.showForm = false;
          this.showError = true;
        })
    }
  },
  components: {
    VueRecaptcha,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sample-request {
  background-color: #f1f1f1;
  background-image: url('../assets/images/diagonal-lines.png');
  background-repeat: repeat;
}
.container {
  background-color: #fff;
  padding: 40px 15px;
  min-height: 500px;
}
.form-header {
  margin-bottom: 25px;
  text-align: center;
}
.form-header h1 {
  color: #274ba7;
  font-size: 42px;
  font-weight: 700;
}
label {
  margin-bottom: 2px;
}
.form-group {
  float: left;
}
h3 {
  color: #274ba7;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 20px;
  font-family: "Barlow", sans-serif;
}
select {
  border-radius: 0px;
}
.btn {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 35px;
}

.error {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .container {
    padding: 40px;
  }
  .btn {
    width: 250px;
  }
  .form-header {
    text-align: left;
  }
}
</style>
