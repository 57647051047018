<template>
  <div class="technology">
    <vue-headful title="Technology Inquiries | Goodyear Chemical" description="Submit technical inquiries related to licensing, technology, partnerships and/or ventures, to Goodyear Chemical here."/>
    <div class="container">
      <b-row>
        <b-col sm="12">
          <b-form id="techRequest" class="techRequest" name="techRequest" @submit="submitTechRequest" v-if="showForm">
            <div class="form-header">
              <h1>Technology Inquiries</h1>
              <p>For questions on licensing, technology, partnerships and/or ventures with Goodyear Chemical please submit the form below with a brief description of your areas of interest.<br/></p>
            </div>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="name">Name</label>
              <b-form-input id="name" v-model="form.name" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-6">
              <label for="phoneNumber">Phone number</label>
              <b-form-input id="phoneNumber" v-model="form.phoneNumber" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-12">
              <label for="email">Email address</label>
              <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-12 col-md-12">
              <label for="comments">Area(s) of interest</label>
              <b-form-textarea id="comments" v-model="form.comments" rows="3" required></b-form-textarea>
            </b-form-group>

            <b-form-group class="form-buttons col-sm-12">
              <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey" ></vue-recaptcha>
              <p class="red">{{this.invalidField}}</p>
              <b-button type="submit" variant="primary" v-if="!loading">Request Information</b-button>
              <b-spinner label="Loading..." v-if="loading"></b-spinner>
            </b-form-group>
          </b-form>

          <div class="gy-form-ty" v-if="showThanks && !showForm">
            <h2>Thank You</h2>
            <p>Your submission has been sent. A Goodyear Chemical representative will respond to your message. </p>
          </div>

          <div class="gy-form-fail" v-if="showError && !showForm">
            <h2>An error has occurred</h2>
            <p>Please try again later. </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import { config } from '../config';

window.dataLayer = window.dataLayer || []

export default {
  name: 'Technology',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phoneNumber: '',
        comments: '',
        gResponse: '',
      },
      showForm: true,
      showThanks: false,
      showError: false,
      loading: false,
      invalidField: ' ',
    }
  },
  computed: {
    recaptchaClientKey: function () {
      return config.RECAPTCHA_CLIENT_KEY;
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        this.invalidField = ' ';
        this.form.gResponse = response;
      } 
    },
    submitTechRequest (evt) {
      evt.preventDefault();

      // recaptcha
      if (this.form.gResponse.length === 0) {
        // reCaptcha not verified
        this.loading = false
        this.invalidField = 'Please verify using reCAPTCHA.'
        return false;
      }

      axios.post('/api/sendTechRequest', {
        name: this.form.name,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        comments: this.form.comments,
        gResponse: this.form.gResponse
      })
        .then(response => {
          console.log(response.data)
          this.showForm = false
          if (response.data.code === 0) {
            this.showThanks = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Technology Request' })
          } else {
            this.showError = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.showForm = false;
          this.showError = true;
        })
    }
  },
  components: {
    VueRecaptcha,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.technology {
  background-color: #f1f1f1;
  background-image: url('../assets/images/diagonal-lines.png');
  background-repeat: repeat;
}
.container {
  background-color: #fff;
  padding: 40px 15px;
  min-height: 500px;
}
.form-header {
  margin-bottom: 25px;
  text-align: center;
}
.form-header h1 {
  color: #274ba7;
  font-size: 42px;
  font-weight: 700;
}
label {
  margin-bottom: 2px;
}
.form-group {
  float: left;
}
h3 {
  color: #274ba7;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  font-family: "Barlow" sans-serif;
  font-weight: 700;
}
textarea {
  border-radius: 0px;
}
.btn {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 35px;
}

.error {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .container {
    padding: 40px;
  }
  .btn {
    width: 250px;
  }
  .form-header {
    text-align: left;
  }
}
</style>
