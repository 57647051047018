<template>
  <div class="Header">
    <b-navbar class="all-sites-nav">
      <b-container>
        <b-navbar-nav class="ml-auto">
          <b-nav-item right href="https://www.goodyear.com/en-US/other-goodyear-sites" target="_blank">Other Goodyear Sites</b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <b-navbar toggleable="lg">
      <b-container id="gynav">
        <b-navbar-brand class="gohome" to="/"><img class="logo" src="../assets/images/logo.png" alt="Goodyear Chemical logo" /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="main">
            <b-nav-item to="/our-company" class="first">Our Company</b-nav-item>
            <b-nav-item to="/products">Products</b-nav-item>
            <b-nav-item to="/technology">Technology</b-nav-item>
            <b-nav-item to="/certifications">Certifications</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/contact-us" right>Contact Us</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
    })
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getWindowWidth (event) {
      this.windowWidth = document.documentElement.clientWidth

      // small screen
      if (this.windowWidth <= 992) {
        // console.log('smaller screen detected!')
        document.getElementById('gynav').classList.add('container-fluid')
        document.getElementById('gynav').classList.remove('container')
      } else {
        // console.log('md-lg screen detected!')
        document.getElementById('gynav').classList.add('container')
        document.getElementById('gynav').classList.remove('container-fluid')
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all-sites-nav.navbar {
  background-color: #0b286f;
  padding: 0 15px 0 0;
  height: 40px;
  overflow-y: hidden;
}
.all-sites-nav li, .all-sites-nav a {
  color: #fd0;
}
.all-sites-nav li:hover, .all-sites-nav li a:hover {
  text-decoration: underline;
}
.navbar {
  background-color: #274ba7;
  color: #fff;
  text-transform: uppercase;
  padding: 0rem 1rem;
}
.navbar-nav .nav-item, .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 16px;
  border: none;
  box-shadow: none;
}
.navbar-nav .nav-item:hover, .navbar-nav .nav-item:hover .nav-link {
  color: #fd0;
}
.logo {
  height: 58px;
  width: auto;
  padding-left: 2%;
}
.ml-auto .nav-item, .ml-auto .nav-item .nav-link {
  color: #fd0;
}
.navbar .navbar-toggler {
  background-color: rgba(255, 221, 0, 0.95);
  border-radius: 0px;
}

@media (max-width: 767px) {
  .logo {
    padding: 5px 2px 5px 2%;
  }
}
@media (max-width: 991px) {
  .navbar-nav .nav-item .nav-link {
    background-color: transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-nav .nav-item:hover .nav-link {
    color: #fd0;
    background-color: rgba(23,60,140,0.5);
  }
}
@media (min-width: 992px) {
  .navbar-nav {
      margin-left: 15%;
  }
  .main.navbar-nav .nav-item.first .nav-link {
    border: none;
    box-shadow: none;
  }
  .main.navbar-nav .nav-item .nav-link {
    border-left: 1px solid rgba(255,255,255,0.2);
    background-color: transparent;
    box-shadow: none;
    margin: 12px 0;
    padding: 3px 10px;
  }
}
</style>
