<template>
  <div class="Footer">
    <footer>
      <div class="wrapper-inner">
        <b-container>
          <b-row>
            <b-col sm="12" md="6" class="footer-link-list">
              <h3>Goodyear Resources</h3>
              <ul>
                <li>
                  <a class="footLink" href="https://jobs.goodyear.com" target="_blank">Careers </a>
                </li>
                <li>
                  <a class="footLink" href="https://corporate.goodyear.com/en-US/" target="_blank">Corporate </a>
                </li>
                <li>
                  <a class="footLink" href="https://corporate.goodyear.com/en-US/about/mission/environmental-health-safety-policy.html" target="_blank"> EHS Policy </a>
                </li>
                <li>
                  <a class="footLink" href="https://www.goodyear.com" target="_blank"> Tires </a>
                </li>
              </ul>
            </b-col>

            <b-col sm="12" md="6" class="footer-link-list">
              <h3>Chemical Quick Links</h3>
              <ul>
                <li>
                  <a class="footLink" href="/certifications">Certifications</a>
                </li>
                <li>
                  <a class="footLink" href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a class="footLink" href="/our-company">Our Company</a>
                </li>
                <li>
                  <a class="footLink" href="/products">Products</a>
                </li>
                <li>
                  <a class="footLink" href="/safety-data-sheets">Safety Data Sheets</a>
                </li>
                <li>
                  <a class="footLink" href="/request-sample">Sample Request</a>
                </li>
                <li>
                  <a class="footLink" href="/technology">Technology</a>
                </li>
              </ul>
            </b-col>
            </b-row>
            <b-row class="footer-end">
              <b-col sm="12" md="7">
                <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
              </b-col>
              <b-col sm="12" md="5">
                <a class="footLink" href="https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy.html" target="_blank">Terms & Conditions and Privacy Policy</a>
              </b-col>
            </b-row>
        </b-container>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background-color: #274ba7;
  background: #274ba7;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  border-top: 2px solid #fd0;
  color: #fff;
  min-height: 300px;
}
.wrapper-inner {
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
h3 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #fff;
}
a, .v-btn {
  color: #fd0;
}
a:hover {
  text-decoration: underline;
  color: #fd0;
}
a {
  text-decoration: none;
}
ul {
  -webkit-padding-start: 0;
  padding-left: 0;
  list-style-type: none;
}
ul li {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 400;
}
.footer-end {
  margin-top: 15px;
  font-size: 16px;
  color: #fff;
}

@media (min-width: 768px) {
  .footer-link-list {
    margin-top: 12px;
    padding-left: 20px;
  }
  .footer-end {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 980px) {
  .footer-link-list {
      padding-left: 40px;
  }
}
</style>
